import React from 'react';
import  '../InfrastructureComponent/Infrastructure.css';
import Desgineimg from '../../assetes/images/desgine.jpg';
import Manufacturingimg from '../../assetes/images/manufacturing.png';
import Testingimg from '../../assetes/images/testing.png';
import Associansimg from '../../assetes/images/result.jpg';
import SCHNEIDERLogo  from '../../assetes/images/i-logo1.png';
import FCGLogo  from '../../assetes/images/i-logo2.png';
import RRLogo  from '../../assetes/images/i-logo3.png';
import Sunlogo from '../../assetes/images/sunlogo.png';
import Table1 from '../../assetes/images/table1.png';
import Table2 from '../../assetes/images/table2.png';



class Infrastructure extends React.Component{
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">Infrastructure</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="desgine-sec">
                        <div className="container">
                            <div className="row">
                               
                                    <div className="col-md-offset- col-md-6 col-sm-12">
                                        <div className="technology-main-inner-graphic">
                                            <img src={Desgineimg} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <div className="main-boxes-inner-content" >
                                            <div className="">
                                                <h2>DESIGN</h2>
                                                <p>Our Experienced & Technically sound team of Engineers are always there to fulfil all the technical requirement of customer according to their need and also to suggest the best system for them. The motto of our design team is to provide the system having 'Engineering with Perfection' to our customer. We have latest designing software’s that make our working efficient and error free. The company has in-house research & development as well as designing facility equipped with latest CAD/CAM technology and specifically customized technology. Our engineers are continuously trained by OEMS on their latest offerings that can be incorporated in our solution offers to make it most optimum and user friendly. There is a special focus on designing future ready communicable and smart products which can assist the customers in analyzing their energy consumption trends.</p>
                                                {/* <div className="governance-main-inner">
                                                    <ul>
                                                        <li>A 60+ year proven track record of innovation, utilizing proprietary IP and a process driven infrastructure to unlock company value resulting in high multiple exits.</li>

                                                        <li>$450M in exits and an overall average of2.5x ROI.</li>

                                                        <li>Over past 10 years, returned $133.8MM of external capital on $125.5MM invested with $43MM of principal actively deployed.</li>

                                                      
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </section>
                    <section className="manufacturing-sec">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-sm-12 scroll-sec">
                                        <div className="main-boxes-inner-content" >
                                            <div className="">
                                                <h2>MANUFACTURING</h2>
                                                <p>This is divided in three units, in houses cnc fabrication, paint-shop and assembly. All shop floors are laid out as per flow manufacturing and TQM requirements to minimize non-essential movement of man and material. The organization has a state-of-the-art electrical control panels manufacturing unit, which is well equipped with all the latest machines and technologies. All the machines are operated by a team of experienced technicians, which has the requisite experience behind them. Our power control panels manufacturing unit is well integrated with several units such as a fabrication section, an electrical wiring & assembly section and an in-house testing & measuring facilities.</p>
                                            </div>
                                            <div className="governance-main-inner">
                                                    <h4>WE HAVE FOLLOWING IN HOUSE FACILITIES:</h4>
                                                    <ul>
                                                        <li>FABRICATION</li>
                                                        <li>PAINTING</li>
                                                        <li>ASSEMBLY WORK</li>
                                                        <li>BUSBAR WORK</li>
                                                        <li>BUSBAR WORK</li>
                                                        <li>H.V. TEST</li>
                                                        <li>MEGGER TEST</li>
                                                        <li>NO LOAD TEST</li>
                                                        <li>FUNCTIONAL TEST</li>
                                                       

                                                      
                                                    </ul>
                                                </div>
                                            <div className="governance-main-inner">
                                                    <ul>
                                                        <li><span>Sheet Metal shop</span><p>Sheet metal is fabricated on the advanced CNC-operated  punch press & press brake for a repeat dimensional accuracy of bending Arc/TIG Welding machines, pneumatic riverting and grinding machines for ensuring high precision and high quality sheet metal work.</p></li>

                                                        <li><span>Welding shop</span>
                                                       <p>- High-grade welding machines and jigs contribute to giving excellent finish to its products.</p>
                                                        <p>- The welding facilities presently include a MIG/CO2 Welding, arc welding & stud welding machines.</p>
                                                        </li>
                                                        <li><span>Pre-treatment</span>
                                                        <p>the 10-tank semi automatic pre-treatment plant is designed for precise surface treatment and preparation of the sheet metal using cold/hot process thereby Improving resistance to oxidation and erosion through an automated conveyorised plant.</p></li>
                                                        <li><span>Powder coating shop</span><p>Power coating being a critical manufacturing process. We have an Semi-automatic conveyorised powder coating plant to ensure superior coating finish and longevity. Power coating quality is ensured by subjecting coated sample panels various checks like DFT, Surface finish Adhesion, Flexibility and impact tests by batch sampling process.</p></li>
                                                        <li><span>Assembly shop</span>
                                                        <p>Systematic assembly line, handled by our technicians and specialists guarantees quality products, along with personnel/ operator safety.</p>
                                                        <p>Our technicians are well trained, so that utmost care is taken in assembling and wiring the switchgears controls. Our specialists have taken care to design each panel, keeping in mind site-requirements and flexibility to house the components in a compact manner, so as to have enough space inside the switchboards.</p>
                                                        </li>
                                                        <li><span>Testing & Q/C</span><p>Our all products are tested before dispatch for electrical safety and functional testing as a per the companies Q/A policy.</p>
                                                        <p><strong>LIST OF PLANT MACHINERY</strong></p>
                                                         <img src={Table1} />
                                                         <p><strong>LIST OF QUALITY CONTROL MACHINERY</strong></p>
                                                         <img src={Table2} />
                                                        </li>
                                                        

                                                      
                                                    </ul>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-md-offset- col-md-6 col-sm-12">
                                        <div className="technology-main-inner-graphic">
                                            <img src={Manufacturingimg} />
                                        </div>
                                    </div>

                                   
                                
                            </div>
                        </div>
                    </section>
                    <section className="desgine-sec">
                        <div className="container">
                            <div className="row">
                               
                                    <div className="col-md-offset- col-md-6 col-sm-12">
                                        <div className="technology-main-inner-graphic">
                                            <img src={Testingimg} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12 scroll-sec">
                                        <div className="main-boxes-inner-content" >
                                            <div className="">
                                                <h2>QUALITY & TESTING</h2>
                                                <p></p>
                                                <div className="governance-main-inner">
                                                    <ul>
                                                        <li><span>In-House Quality testing</span><p>All our electrical automation products are manufactured with efficient quality checks.  The organization’s motto is to offer best quality and cost effective solutions to our clients. Quality is one of the main trust full pillars at INDITRON. In house testing of panels is done by qualified & experienced engineers by modern testing facilities available in house. Various quality checks are done at every stage of manufacturing to avoid any discrepancy or mistake. After final assembly & wiring, routine tests & in house performance tests are carried out to check the physical & working performance of panel as per the Drawing.</p></li>

                                                        <li><span>CPRI Certification</span><p>Our all electrical control panels and power distribution boards are manufactured with all guidelines & instructions of CPRI.</p></li>

                                                        <li><span>Client Satisfaction</span>
                                                        <p>We offer quality products as well as highly reliable and efficient services to deliver maximum client satisfaction. Our customized range meets all the guidelines and fit within the budget of our clients. We have successfully catered to our large client base with our energy optimization and energy saving services. We strongly believe that client satisfaction is of paramount importance in today's highly competitive market. Thus, along with our highly proficient and talented team of professionals we strive to delivery maximum client satisfaction.</p></li>
                                                        <li><span>R & D</span><p>Automation Instrumentations are changing every day from the day of its existence. Our R & D Department develops correct control panels & services which are economically viable and long lasting. To further enhance our electrical control panel products features, we have a state-of-the-art Auto CAD Centre with all the latest tools for design and development.</p></li>
                                                       

                                                      
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </section>
                    <section className="manufacturing-sec">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-12 col-sm-12">
                                        <div className="main-boxes-inner-content" >
                                            <div className="">
                                                <h2>ASSOCIATION</h2>
                                                <p>We are association with following reputed brands </p>
                                                <div className="logo-main-inner">
                                                    <ul>
                                                         <li><a href="https://www.se.com/in/en/" target="_blank"><img src={SCHNEIDERLogo} /></a></li>
                                                        <li><a href="http://www.fcg-india.net/" target="_blank"><img src={FCGLogo} /></a></li>
                                                        <li><a href="https://www.rrkabel.com/" target="_blank"><img src={RRLogo} /></a></li>
                                                       
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="certificate">
                                            <img src={Associansimg} />
                                        </div>
                                    </div>

                                   
                                
                            </div>
                        </div>
                    </section>
                
            </div>
        );
    }
}

export default Infrastructure;