import React from 'react';
import ReactDOM from 'react-dom';
import Slider from './Slider';
import AboutSection from './AboutSection';
import Service from './Service';
import OurSection from './OurSection';
import LogoComponent from './LogoComponent'
class Home extends React.Component{
    render(){
        return(
            <div>
              <Slider/>
              <OurSection/>
              {/* <Service/> */}
              {/* <AboutSection/> */}
              <LogoComponent/>
            </div>
        );
    }
}
export default Home;