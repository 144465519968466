import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LTPANEL from '../../../assetes/images/ltp/LTPANEL.jpg';
import './LowTension.css'
class LowTension extends React.Component {
    render() {
        return (
            <div>
            <section id="banner-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 banner-heading-div">
                            <p className="banner-heading-text">LOW TENSION PANEL (LT PANEL)</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">
                                <img src={LTPANEL} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="man-content">

                            <p>Our vast range comprises LT Panels that are manufacturing with using high quality electrical components like ACB, MCCB, SFU, Protection Relay with Aluminium & Copper Busbar as per Requirement of Clients, Consultants Specifications. That will be huge Application in Industries, Big Commercial Complex, these LT panel are designed in such a manner that installed in substation to enable to for proper Power Distribution selective fault protection & contact isolation capabilities. L T panels are design with such a manner that Single / Multiple incomer, Bus coupler with proper interlocking to enable for power distribution selective fault protection & selective. Fault Level will be maintain with Transformer Specifications.</p>
                        </div>

                    </div>
                </div>
            </div>

            {/* <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div >
                                <table>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Operation,Control & protection of distribution network with or without PLC/BMC Connectivity</td>

                                    </tr>
                                    <tr>
                                        <th>System</th>
                                        <td>3 Phase 3 or 4 wire, 50Hz</td>

                                    </tr>
                                    <tr>
                                        <th>Rated Current</th>
                                        <td>Upto 6300 Amps</td>
                                    </tr>
                                    <tr>
                                        <th>Operational Voltage</th>
                                        <td>110/230v 415 AC</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Insulation</th>
                                        <td>1000v</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Service Voltage</th>
                                        <td>690v</td>
                                    </tr>
                                    <tr>
                                        <th>Ambient Temperature</th>
                                        <td>45Deg C</td>
                                    </tr>
                                    <tr>
                                        <th>Short Time Current</th>
                                        <td>50KA for 1Sec,105KA peak</td>
                                    </tr>
                                    <tr>
                                        <th>Degree of Protection</th>
                                        <td>IP42,54,55,65 as per Custom Requirement</td>
                                    </tr>
                                    <tr>
                                        <th>Form of sepration</th>
                                        <td>Upto 3B/4B(Optional Type 5)</td>
                                    </tr>
                                    <tr>
                                        <th>Type Tested for</th>
                                        <td>Short Time Current withstand Temperature Rise Degree of Protection</td>
                                    </tr>
                                    <tr>
                                        <th>Bus Bar</th>
                                        <td>Aluminium or Copper</td>
                                    </tr>
                                    <tr>
                                        <th>Ref.Standard</th>
                                        <td>IEC60439-1&2, IS:8623</td>
                                    </tr>
                                    <tr>
                                        <th>Feeder Configuration</th>
                                        <td>Drowout or Fixed,Extendable Type</td>
                                    </tr>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}
        </div>
        );
    }
}

export default LowTension;