import React from 'react';
import ReactDOM from 'react-dom';
import Repairimg from '../../assetes/images/repair.png';
import Porjectimg from '../../assetes/images/hr.png';
import Serviceimg from '../../assetes/images/support.png';
import { Link } from 'react-router-dom';


class Home extends React.Component{
    render(){
        return(
            <div>
               <section className="overview-block-ptb bg-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="heading-title text-center">
                                    <h2 className="title hps-tw-7">Services We Provide</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
							<div className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25" >
								<div className="bg_overlay"></div>
								<div className="teaser_content">
									<div className="teaser text-center">
										<div className="teaser_icon grey size_big">
											<img src={Repairimg} />
										</div>
										<h4 className="text-uppercase topmargin_25">OPERATION & MAINTENANCE</h4>
										<hr className="divider_2_40 bottommargin_25" />
										<p className="darken-font-color">
										Building on its technical and organizational expertise, INDITRON offers complete range of Operations & Maintenance (O&M) services to power plant owners.
										</p>
										<Link to="services" className="theme_button color1">Read More</Link>
									</div>

								</div>
							</div>
						</div>
                        <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
							<div className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25" >
								<div className="bg_overlay"></div>
								<div className="teaser_content">
									<div className="teaser text-center">
										<div className="teaser_icon grey size_big">
											<img src={Porjectimg} />
										</div>
										<h4 className="text-uppercase topmargin_25">PROJECT CONSULTANT</h4>
										<hr className="divider_2_40 bottommargin_25" />
										<p className="darken-font-color">
										INDITRON ELECTRONICS have in house design capabilities with team of experts having expertise in project drawings and CAD programs. 
										</p>
										<Link to="services" className="theme_button color1">Read More</Link>
									</div>

								</div>
							</div>
						</div>
                        <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
							<div className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25" >
								<div className="bg_overlay"></div>
								<div className="teaser_content">
									<div className="teaser text-center">
										<div className="teaser_icon grey size_big">
											<img src={Serviceimg} />
										</div>
										<h4 className="text-uppercase topmargin_25">SERVICES & AMC</h4>
										<hr className="divider_2_40 bottommargin_25" />
										<p className="darken-font-color">
										With years of experience and expertise in this domain, INDTRON are offering a satisfied Plant Electrical Items & water treatment plants maintenance service to our clients. 
										</p>
										<Link to="services" className="theme_button color1">Read More</Link>
									</div>

								</div>
							</div>
						</div>
                       
                     
                     
                        </div>
                    </div>
                    
               </section>
            </div>
        );
    }
}
export default Home;