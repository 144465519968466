import React from 'react';
import ReactDOM from 'react-dom';
import '../ProductsComponent/Productcomponent.css';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PCCPANEL from '../../assetes/images/pcc/PCCPANEL.jpg';
import PCCPANEL2 from '../../assetes/images/pcc/PCCPANEL2.jpg';
import './PowerControl.css'
class PowerControl extends React.Component {
    render() {
        return (
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">POWER CONTROL CENTER (PCC PANEL)</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="man-content">
                                    <img src={PCCPANEL} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="container section-padding">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">
                                <img src={PCCPANEL2} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                            </div>

                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">

                                <p>Power Control Centre is used for distribution and control of various power source used in industry. Normally Power Control Centers is installed near power source hence fault level is high. Busbar system in Power Control Centers are designed to suit the fault level as well as temperature rise to 40°c above ambient. Ample space is provided for cable termination. Various protections viz short circuit, overload, earth fault, under voltage etc are provided to protect source and equipment.</p>
                            </div>

                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div >
                                    <table>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Operation,Control & protection of distribution network with or without PLC/BMC Connectivity</td>

                                        </tr>
                                        <tr>
                                            <th>System</th>
                                            <td>3 Phase 3 or 4 wire, 50Hz</td>

                                        </tr>
                                        <tr>
                                            <th>Rated Current</th>
                                            <td>Upto 6300 Amps</td>
                                        </tr>
                                        <tr>
                                            <th>Operational Voltage</th>
                                            <td>110/230v 415 AC</td>
                                        </tr>
                                        <tr>
                                            <th>Rated Insulation</th>
                                            <td>1000v</td>
                                        </tr>
                                        <tr>
                                            <th>Rated Service Voltage</th>
                                            <td>690v</td>
                                        </tr>
                                        <tr>
                                            <th>Ambient Temperature</th>
                                            <td>45Deg C</td>
                                        </tr>
                                        <tr>
                                            <th>Short Time Current</th>
                                            <td>50KA for 1Sec,105KA peak</td>
                                        </tr>
                                        <tr>
                                            <th>Degree of Protection</th>
                                            <td>IP42,54,55,65 as per Custom Requirement</td>
                                        </tr>
                                        <tr>
                                            <th>Form of sepration</th>
                                            <td>Upto 3B/4B(Optional Type 5)</td>
                                        </tr>
                                        <tr>
                                            <th>Type Tested for</th>
                                            <td>Short Time Current withstand Temperature Rise Degree of Protection</td>
                                        </tr>
                                        <tr>
                                            <th>Bus Bar</th>
                                            <td>Aluminium or Copper</td>
                                        </tr>
                                        <tr>
                                            <th>Ref.Standard</th>
                                            <td>IEC60439-1&2, IS:8623</td>
                                        </tr>
                                        <tr>
                                            <th>Feeder Configuration</th>
                                            <td>Drowout or Fixed,Extendable Type</td>
                                        </tr>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default PowerControl;