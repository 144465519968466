import React from 'react';
import ReactDOM from 'react-dom';
import {Table} from 'react-bootstrap';
import '../SingleComponent/SingleComponent.css';
class Electricalpannel extends React.Component{
    render(){
        return(
            <div><section id="banner-section" >
            <div className="container">
                <div className="row">
                    <div className="col-md-12 banner-heading-div">
                        <p className="banner-heading-text">Electrical Pannel</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="post-desc">
           <div className="container">
              <div className="row">
                  <div className="post-content">
                     <p>
                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                     </p>
                     <p>
                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  
                         </p>
                      
                  </div>
              </div>
              <div className="project-list">
                   <div className="project-list-inner">
                      <h3>LIST OF MAJOR PROJECT EXCUTED IN PANEL BOARD, ELECTRICAL & AUTOMATION</h3>
                   </div>
                   <div className="project-table">
                   <Table striped bordered hover>
  <thead>
    <tr>
      <th>#</th>
      <th>PROJECT Name</th>
      <th>SCOPE OF WORK</th>
      <th>FY YEAR</th>
      <th>PROJECT COST</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Indian Institute Of Management Indore</td>
      <td>Supply, Installations , Testing And Commissoning <br/>Of LT Panel CUM APFC Panel</td>
      <td>2014-2015</td>
      <td>47 Lack</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Indian Institute Of Management Indore</td>
      <td>Supply, Installations , Testing And Commissoning <br/>Of LT Panel CUM APFC Panel</td>
      <td>2014-2015</td>
      <td>47 Lack</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Indian Institute Of Management Indore</td>
      <td>Supply, Installations , Testing And Commissoning <br/>Of LT Panel CUM APFC Panel</td>
      <td>2014-2015</td>
      <td>47 Lack</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Indian Institute Of Management Indore</td>
      <td>Supply, Installations , Testing And Commissoning<br/> Of LT Panel CUM APFC Panel</td>
      <td>2014-2015</td>
      <td>47 Lack</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Indian Institute Of Management Indore</td>
      <td>Supply, Installations , Testing And Commissoning<br/> Of LT Panel CUM APFC Panel</td>
      <td>2014-2015</td>
      <td>47 Lack</td>
    </tr>
    
  </tbody>
</Table>
                   </div>
              </div>
           </div>
        </section>
        </div>
        );
    }
}

export default Electricalpannel;
