import React from 'react';
import ReactDOM from 'react-dom';

class ProductComponent extends React.Component{
    render(){
        return(
            <div>
                Product
            </div>
        );
    }
}

export default ProductComponent;