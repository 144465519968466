import React from 'react';
import ReactDOM from 'react-dom';
import '../SingleManufacturingComponent/SingleComponent.css';
import { Link } from 'react-router-dom';
import WaterImage from '../../../assetes/images/new/water.jpg';

class Singlewatertreatment extends React.Component{
    
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text"> WATER TREATMENT PLANT</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-post-sec">
                    <div className="container">
                      <div className="row post-about">
                          <div className="col-md-6 post-img">
                          <img src={WaterImage}/>
                          </div>
                          <div className="col-md-6 post-content">
                          <h3 class="small-title hps-tw-6 hps-mb-10"> WATER TREATMENT PLANT</h3>
                          <p>We at Fluid Systems are engaged in manufacturing and supplying a wide range of Water Treatment Plants, Sewage Treatment Plants, Effluent Treatment Plants and Packaged Water Treatment Plants. Apart from these we also offer package sewage treatment, combined sewage cum food waste treatment plants and combined sewage cum effluent treatment plants. We also offer effective turnkey solutions for these plants to our clients. Our entire range of plants and services are widely demanded in pharmaceutical industry, chemical manufacturing industry and food processing industry to name a few.</p>
                            <p>Our tremendous success in this highly competitive sector is solely credited to the resources owned by us and our manpower being one of them. We have a team of professionals who have immense expertise in handling the sophisticated manufacturing process and later in providing effectual turnkey services to our clients.

We have an excellent infrastructure to design, engineer, manufacture, carry out erection and commissioning the complete range of water treatment plant and systems to suit various requirements.

</p> 
                             </div>
                      </div>
                    </div>
                </section>
                <section className="post-provide-sec">
                   <div className="container">
                        <div className="row post-provide-title">
                        <h3>Water Tertreatment We Provide</h3>
                        </div>
                        <div className="row">
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>REVERSE OSMOSIS PLANT (RO PLANT) </Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>SOFTNER PLANT</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>DEMINERALISATION PLANT (DM PLANT) </Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>EFFLUENT TRANSFER PLANT (ETP PLANT) </Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>Operation</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>SEWAGE TRANSFER PLANT (STP PLANT) </Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>MBBR technology</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>Sequential Batch Reactor (SBR)</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>Combined Sewage Cum Effluent Treatment Plants</Link>
                             </div>
                         </div>
                         
                         
                        </div>
                   </div>
                </section>
                <section className="normal-content-sec">
                  <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                        <div className="governance-main-inner">
    <ul>
		<li>
		    <span>REVERSE OSMOSIS PLANT (RO PLANT) </span>
		    <p>Reverse osmosis is the process of forcing a solvent from a region of high solute concentration through a membrane to a region of low solute concentration by applying a pressure in excess of the osmotic pressure. This is the reverse of the normal osmosis process, which is the natural movement of solvent from an area of low solute concentration, through a membrane, to an area of high solute concentration when no external pressure is applied. The membrane here is semipermeable, meaning it allows the passage of solvent but not of solute.</p>
		    <p>To illustrate, imagine a semi permeable membrane with fresh water on one side and a concentrated aqueous solution on the other side. If normal osmosis takes place, the fresh water will cross the membrane to dilute the concentrated solution. In reverse osmosis, pressure is exerted on the side with the concentrated solution to force the water molecules across the membrane to the fresh water side.
		    </p>
		    <p>The membranes used for reverse osmosis systems have a dense polymer barrier layer in which separation takes place. Since Reverse Osmosis does not occur naturally, it must be created by applying pressure to the high solids water in order to force it through the membrane, with pressures from 8 - 14 bar for fresh and brackish water, and 40 - 70 bar for seawater, which has around 24 bar (350 psi) natural osmotic pressure which must be overcome.</p>
			 <p>INDITRON ELECTRONICS’S RO Plants work on the Crossflow Filtration method, which takes the feed water and uses a percentage of it as a wash or reject stream, removing the solids during the filtration process. </p>

			 <p>The product flow of an RO Plant is mainly a function of temperature and pressure. System recovery (product divided by feed) is limited by the characteristics of the feed water and can be controlled through the use of recycle stream. Product quality is based on a percentage of dissolved solids fed to the membrane. There should be an economic balance between product quality and system recovery. High recoveries increase concentration of dissolved solids in the system which degrades quality, but high recoveries make the system work more efficiently and decrease waste. </p>

			 <p>Reverse Osmosis Plants do not deliver to service all of the water that is fed to them. During operation, some of the incoming water is used to wash down the membrane, and only part becomes finished product water. Purified water is referred to as product and wastewater is referred to as concentrate, or reject. The percent of water delivered as product is called the recovery, and depends upon the membrane and on total RO Plant design considerations. </p>
			 <p>The feed water, depending on its source, may contain various concentrations of suspended solids and dissolved matter. Suspended solids may consist of inorganic particles, colloidal particles and biological impurities such as microorganisms and algae. Dissolved matter may consist of highly soluble salts, such as chlorides, and sparingly soluble salts, such as carbonates, sulfates, and silica. </p>

			 <p>During the Reverse Osmosis process, the volume of feed water decreases, and the concentration of suspended particles and dissolved ions increases. Suspended particles may settle on the membrane surface, thus blocking feed channels and increasing pressure drop across the system. Sparingly soluble salts may precipitate from the concentrate stream, create scale on the membrane surface, and result in lower water permeability through the RO membranes. This process of formation of a deposited layer on a membrane surface is called membrane fouling and results in performance decline of the RO system. The objective of the feed water pretreatment process is to improve the quality of the feed water to the level which would result in reliable operation of the RO membranes. Based on the raw water quality, the pretreatment process for RO Plants may consist of all or some of the following treatment steps:</p>
			 <ul className="inner-list">
			    <li>Clarification followed by Sand Filtration for Turbidity removal</li>
			    <li>Water disinfection with chlorine</li>
			    <li>Hardness reduction by Softening</li>
                <li>Addition of scale inhibitor</li>
			    <li>Reduction of free chlorine using sodium bisulfite/ Activated carbon filters</li>
			    <li>Final removal of suspended particles using cartridge filters</li>
			  
			 </ul>


		</li>
		<li>
		    <span>SOFTNER PLANT </span>
		    <p>Softeners are widely used for reducing hardness from water. Softening is a reversible ion exchange process wherein the hardness forming ions are exchanged with sodium ions present in the resin group.
The Water Softeners are charged with a high capacity polystyrene bead cation exchange resin in sodium form. When hard water passes through this resin column, the hardness forming calcium and magnesium salts are replaced with sodium salts which do not posses any hardness properties. This softening process is continuous until the material is exhausted of sodium salts when it is regenerated with a solution of common salt.
</p>
<p>
The Water Softener units are designed on the advanced Co current/counter-current principle that ensures consistent good quality treated water.

A typical water softening Plant consists of pre-filters, Water Softener Vessel with Resin, Brine Tank, and accessories.

The Water Softener units are available in a wide range of materials like FRP / LDPE / MS /MS Rubber Lined / Stainless Steel.
</p>
<p>
These Water Softeners come with Multiport Valves/ Diaphragm Valves and designed to give long trouble free service. These Water Softener units are easy to install and does not require any special foundation & needs minimum maintenance.
</p>
		</li>
		<li>
		    <span>DEMINERALISATION PLANT (DM PLANT)</span>
		    <p>Demineralisation is the most proven and reliable technology used in water treatment to remove minerals, using the principles of ion exchange. De-gasification & polishing are used to produce mineral-free water</p>
		</li>
		<li>
		    <span>EFFLUENT TRANSFER PLANT (ETP PLANT) </span>
		    <p>We manufacture and supply effluent treatment Plants, Sewage Treatment Plants, Common Effluent Treatment Plants & waste water treatment Plant and associated equipment and offer sophisticated and innovative solutions to purify Waste Water
</p>
<p>
Used all over India, our effluent treatment plants helps in solving the environmental pollution problem created by the waste material of a company without exposing the same to the environment.
</p>
<p>
The effluent treatment plants are designed to treat the effluent coming from different process of the plant. The treatment of different effluents varies with the type of effluent.</p>
<p>
The industrial effluent treatment plants involve different stages of treatment including physio- chemical treatment and biological treatment followed by tertiary treatment. Integrated functions with simple control make the operation much easier. Our engineers can also custom design the effluent treatment plants based on the specific application requirement of the customers. Our Industrial effluent treatment plants have a compact design and can be easily installed on the site.
</p>
		</li>
		<li>
		    <span>Operation</span>
		    <p>Our effluent treatment plant and system are based on the aerobic respiration method; it consists mainly of three stages: primary treatment, secondary bio-treatment, and tertiary treatment. The effluent water is passed through various processes such as chemical dosing, aeration, and settling. The final treatment is of filtration cum absorption, which takes place by filters. Finally, the processed water goes for advance treatment and we get usable water which can be used further for irrigation and other purposes.</p>
		    <p><strong>Effluent Treatment Plants- Applications:</strong></p>
		    <ul className="inner-list">
			    <li>Plating & coating industry</li>
			     <li>Coolant treatment</li>
			      <li>Pickling industry</li>
			       <li>Paint booths</li>
			       <li>Food Industries </li>
 <li>Manufacturing industry </li>
 <li>Pharmaceutical industry </li>
 <li>Chemical & paint manufacturing companies </li>
 <li>Textile & dyes </li>
 <li>Dairy industry </li>

			        

			  
			 </ul>
		</li>
		<li>
		    <span>SEWAGE TRANSFER PLANT (STP PLANT) </span>
		    <p>We provide Sewage Treatment Plants that are designed and manufactured using Activated Sludge Process (ASP) technology. The water treated using our plant can also be used for flushing and washing. We also design plants using other advanced technologies such as MBBR technology and Sequential Batch Reactor (SBR). These technologies are discussed below:</p>
		</li>
		<li>
		    <span>MBBR technology</span>
		    <p>Better performing than ASP, it needs less space and thus saves money. This technique is perfect to use where there is space constraint. The water treated using this technology can even be used for washing and gardening.

</p>
		</li>
		<li>
		    <span>Sequential Batch Reactor (SBR)</span>
		    <p>It helps in treating the waste water in Batches and hence it is perfect to use where the waste water generated per day is very low. The technology also helps in saving the running cost and low sludge generation. No need of separate clarifier or settling tank.</p>
		    <p>We offer a huge range of <strong>Sewage Treatment Plants</strong>, which are used in the national as well as international market. These products are manufactured using latest techniques using modern machines and we offer these products in a cost effective as well as reliable manner. Our products are easily available in different specifications and we check these on the basis of different quality parameter using various quality controller. These products are available with customized services to our clients. </p>
		</li>
		<li>
		    <span>Combined Sewage Cum Effluent Treatment Plants</span>
		    <p>We offer a wide range of Combined Sewage cum Effluent Treatment Plants that satisfies two purposes simultaneously. These are efficiently designed to treat and manage both sewage and effluent at the same time. This plant proficiently drains out water from sewage and effluents and hence making it usable for other application such as gardening, farming and washing. Our range of combined sewage cum effluent treatment plants is available in standard specification and can also be customized to meet the varied requirements of our clients.</p>
		</li>
		


		
    </ul>
</div>



                        </div>
                    </div>
                  </div>
                </section>
                
            </div>
        );
    }
}

export default Singlewatertreatment;