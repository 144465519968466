import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BUSDUCT from '../../../assetes/images/bus/BUSDUCT.jpg';

import './Busduct.css'
class Busduct extends React.Component {
    render() {
        return (
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">BUSDUCT</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="man-content">
                                    <img src={BUSDUCT} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">

                                <p>We offer highly efficient LT Bus Duct and rising mains. This is offered with both indoor and outdoor construction with IP42/55 degree of protection. The type of bus duct is offered in aluminum and copper based and manufactured as per the International Standards. INDITRON designs bus duct systems in a way that it allows you to have multiple outputs from it without any power loss. Our manufactured Bus Bar Chambers needs very less maintenance</p>
                            </div>

                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div >
                                    <table>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Operation, Control & protection of distribution network with or without PLC/BMS Connectivity</td>

                                        </tr>
                                        <tr>
                                            <th>System</th>
                                            <td>3 Phase 3 or 4 wire, 50Hz</td>

                                        </tr>
                                        <tr>
                                            <th>Rated Current</th>
                                            <td>Upto 6300 Amps</td>
                                        </tr>
                                        <tr>
                                            <th>Operational Voltage</th>
                                            <td>415v AC</td>
                                        </tr>
                                        <tr>
                                            <th>Rated Insulation</th>
                                            <td>1000v</td>
                                        </tr>
                                        <tr>
                                            <th>Rated Service Voltage</th>
                                            <td>690v</td>
                                        </tr>
                                        <tr>
                                            <th>Ambient Temperature</th>
                                            <td>45Deg C</td>
                                        </tr>
                                        <tr>
                                            <th>Short Time Current</th>
                                            <td>50KA for 1Sec,105KA peak</td>
                                        </tr>
                                        <tr>
                                            <th>Degree of Protection</th>
                                            <td>IP42,54,55,65 as per Custom Requirement</td>
                                        </tr>
                                        <tr>
                                            <th>Form of sepration</th>
                                            <td>Upto 3B/4B(Optional Type 5)</td>
                                        </tr>
                                        <tr>
                                            <th>Type Tested for</th>
                                            <td>Short Time Current withstand Temperature Rise Degree of Protection</td>
                                        </tr>
                                        <tr>
                                            <th>Type</th>
                                            <td>Totally enclosed, Air insulated,Non segregated</td>
                                        </tr>
                                        <tr>
                                            <th>Enclosure</th>
                                            <td>Epoxy Powder Cated CRCA Sheet</td>
                                        </tr>
                                        <tr>
                                            <th>Neutral</th>
                                            <td>50% /100%(Optional)</td>
                                        </tr>
                                        <tr>
                                            <th>Earthing</th>
                                            <td>Separate Earth bus provided on enclosure</td>
                                        </tr>
                                        <tr>
                                            <th>Bus Bar Insulation</th>
                                            <td>Black PVC insulation with colour coding for phase identification black matt paint for bus rating 2500A and above</td>
                                        </tr>
                                        <tr>
                                            <th>Ref.Standard</th>
                                            <td>IEC60439-1&2.IS:8623</td>
                                        </tr>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Busduct;