import React from 'react';
import ReactDOM from 'react-dom';
import '../SingleManufacturingComponent/SingleComponent.css';
import { Link } from 'react-router-dom';
import ElectricpannelImage from '../../../assetes/images/banner-two.jpg';

class SingleElectricalPannel extends React.Component{
    
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">Electrical Panel</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-post-sec">
                    <div className="container">
                      <div className="row post-about">
                          <div className="col-md-6 post-img">
                          <img src={ElectricpannelImage}/>
                          </div>
                          <div className="col-md-6 post-content">
                          <h3 class="small-title hps-tw-6 hps-mb-10">Electrical Panel</h3>
                          <p>Our range of automatic electrical control panel products are known for its reliable approach, efficiency, & excellence and thus, are utilized by industries such as Chemical, Textile, Petrochemical, Pharmaceuticals, Heavy Metal, Food Processing, and Water Treatment, Shopping Malls & Plaza, Refineries, Oil Mills, Iron & Steel, Pump Houses, and Sewage Plants.</p>
                             </div>
                      </div>
                    </div>
                </section>
                <section className="post-provide-sec">
                   <div className="container">
                        <div className="row post-provide-title">
                        <h3>Electrical Panel We Provide</h3>
                        </div>
                        <div className="row">
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>Electrical distributional pannel</Link>
                             </div>
                         </div>
                         
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>MOTOR CONTROL CENTER (MCC PANEL)</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>AUTOMATIC POWER FACTOR CORRECTION PANEL (APFC PANEL)</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>MAIN POWER DISTRIUTION BOARD (MPDB PANEL) / MAIN LIGHTING DISTRIUTION BOARD (MLDB PANEL)</Link>
                             </div>
                         </div>
                        
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>PROGRAMMABLE LOGIC CONTROLLER PANEL (PLC PANEL)</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>VARIABLE FREQUENCY DRIVE PANEL (VFD PANEL) / SOFTSTARTER PANEL</Link>
                             </div>
                         </div>
                        
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>AUTO MAINS FAILURE PANEL (AMF PANEL)</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>DG SYNCHRONIZATION PANEL</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>INSTRUMENTATION PANEL</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>BUSDUCT</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>CUSTOMIZED PANEL/ OEM MANUFACTURING</Link>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="feature-checked">
                               <Link><i class="fa fa-check-square-o"></i>ENERGY MANAGEMENT SYSTEMS (EMS SYSTEM) </Link>
                             </div>
                         </div>
                         
                        </div>
                   </div>
                </section>
                <section className="normal-content-sec">
                  <div className="container">
                    <div className="row">
                         <div className="col-md-12">
                         <div class="governance-main-inner">
                           <ul>
                           <li id="list1"><span>LOW TENSION PANEL (LT PANEL)</span>
                                        <p>Our vast range comprises LT Panels that are manufacturing with using high quality electrical components like ACB, MCCB, SFU, Protection Relay with Aluminium & Copper Busbar as per Requirement of Clients, Consultants Specifications. That will be huge Application in Industries, Big Commercial Complex, these LT panel are designed in such a manner that installed in substation to enable to for proper Power Distribution selective fault protection & contact isolation capabilities. L T panels are design with such a manner that Single / Multiple incomer, Bus coupler with proper interlocking to enable for power distribution selective fault protection & selective. Fault Level will be maintain with Transformer Specifications.</p>
                                        </li> 
<li>
	<span>POWER CONTROL CENTER (PCC PANEL) </span>
	<p>Power Control Centre is used for distribution and control of various power source used in industry. Normally Power Control Centers is installed near power source hence fault level is high. Busbar system in Power Control Centers are designed to suit the fault level as well as temperature rise to 40°c above ambient. Ample space is provided for cable termination. Various protections viz short circuit, overload, earth fault, under voltage etc are provided to protect source and equipment.</p>
</li> 
<li>
	<span>MOTOR CONTROL CENTER (MCC PANEL) </span>
	<p>MCC is a group of starter panels for a process plant of various drives (Motor Control Centre). The starter panel controls only one consumer. Multiple groups of starter panel are connected in one Bus called MCC. MCC Panel has capability to start by DCS or in Remote or by automation. The MCC Panel can be availed from us at competitive prices.</p>
</li> 
<li>
	<span>AUTOMATIC POWER FACTOR CORRECTION PANEL (APFC PANEL)-</span>
	<p>An Automatic Power Factor Correction (Controller) panel known as APFC panel is used to Save Energy by consistently maintaining higher power factor. Low Power Factor leads to poor power efficiency, thereby increasing the apparent power drawn from the distribution network. This result in overloading of distribution devices within the Industry. Our APFC Panels are a modern Powerful System constituting a Technological Breakthrough in the field of Power Factor Control. Our Automatic Power Factor Correction Panel is ready to connect, for the central compensation of reactive power in three-phase Supply Systems for Steel Rolling Mills, Cement Plants, Engg. Industries, Commercial Buildings etc.</p>
</li> 
<li>
	<span>MAIN POWER DISTRIUTION BOARD (MPDB PANEL) / MAIN LIGHTING DISTRIUTION BOARD (MLDB PANEL) </span>
	<p>– Our Power Distribution Panel are using in applications like power-distribution, control, ground-fault protection and monitoring. Apart from this, Power Distribution Panel also serves as alarm panel for freeze protection, broad temperature-maintenance and heat-tracing applications. These Power Distribution Panels are integrated with ground-fault circuit breakers in an enclosure having assembled circuit-breaker panel board with or without alarms contacts.</p>
  <p>Lighting Distribution Boards are an integral and essential part of every building project and it must be designed, manufactured and installed all in accordance with the safety standards. In most of the cases it operates a layman wherein others operate dedicated or trained personnels. This new generation of panelboard products represents the high level of engineering innovation expected from INDITRON ELECTRONICS.</p>

</li> 
<li>
	<span>PROGRAMMABLE LOGIC CONTROLLER PANEL (PLC PANEL)</span>
	<p>PLC automation panels or programmable logic controller (PLC) is the most effective choice widely used for electronic and electrical circuit fittings. As the leading PLC panel manufacturers and suppliers in India, our panels have the capability to give excellent output at low power consumption. Our PLC panels are well integrated with effective consulting and programming services for cutting edge remote communications. Because of compact size and solid PLC logic, our PLC panels are taken as highly valuable over conventional relay based systems.</p>
</li> 
<li>
	<span>VARIABLE FREQUENCY DRIVE PANEL (VFD PANEL) / SOFTSTARTER PANEL</span>
	<p>VFD Panel or variable frequency Drive is widely used in the speed control application of the heavy duty motors. Their main function is to regulate the speed of the motor or pump. We are offering our products range in different specifications in order to meet the diverse requirements of the industry. Banking on our innovative approach, we have used cutting edge technology while manufacturing Electrical VFD panels. Our experienced team has used graded raw materials to manufacture our products to ensure robust construction. The high strength is provided so that these can withstand tough working conditions.</p>
  <p>Soft Starter are used in several industries. Their main function is to reduce the torque and stress during the functioning of the motor. Our products are based on the precision engineering in order to offer flawless operation over a long operating life. Soft Starter Panels are manufactured at our in house manufacturing facility which is equipped with state of the art technology. We use graded raw materials while manufacturing our products. For clients’ satisfaction, we test our products on different parameters during the production.</p>

</li> 

<li>
	<span>AUTO MAINS FAILURE PANEL (AMF PANEL) </span>
	<p>Our robustly constructed Automatic Mains Failure (AMF Panels) find wide applications in sectors like foundries, apartments, textiles and other varied industries. These panels come with various functions and timers, which help in the efficient running of the AMF panels. These also have metering facilities for monitoring of the frequency/current/ voltage.AMF panels are quite useful for automatic changeover from mains to stand-by generator at the time of power failure. </p>
</li> 
<li>
	<span>DG SYNCHRONIZATION PANEL </span>
	<p>We are actively engaged in manufacturing and export of high performance DG Synchronization Panel which comes complete with Controlling & Protection Relays capable of synchronizing of more than one DG sets / with Mains & DG and with necessary protections. The offered product is precisely designed and manufactured by utilizing optimum quality raw material and sophisticated technology. Furthermore, we offer this DG Synchronization panel in various technical specifications at affordable prices.</p>
</li> 
<li>
	<span>INSTRUMENTATION PANEL </span>
	<p>Instrumentation Panel is a most usable device in the automation industry. It is widely used in the automobile, production and automation & control industry. We are offering these in wide array of specifications in order to meet the end requirements of the industry.Our instrument panel are based on the cutting edge technology. We have used state of the art technology along with the graded raw materials. Our technically sound team has thoroughly tested our products during various phases of development to ensure flawless operation.</p>
</li> 
<li>
	<span>FIRE FIGHTING PANEL </span>
	<p><strong>INDITRON ELECTRONICS</strong> is a renowned <strong>Fire Panel Manufacturers</strong>, suppliers & exporters based in India. This is widely used in domestic as well as in commercial applications in the fire detection system. We have manufactured this product strictly in accordance to the international technology to ensure reliable operation. All of our products are manufactured at our in house manufacturing unit. We are fully equipped with latest technology & develop our <strong>Electrical Panel</strong> based on this. Moreover, our engineering team uses graded raw materials that we have purchased from the reliable vendors to create our <strong>Fire fighting Panel</strong>.</p>
</li> 
<li>
	<span>BUSDUCT</span>
	<p>We offer highly efficient LT Bus Duct and rising mains. This is offered with both indoor and outdoor construction with IP42/55 degree of protection. The type of bus duct is offered in aluminum and copper based and manufactured as per the International Standards. INDITRON designs bus duct systems in a way that it allows you to have multiple outputs from it without any power loss. Our manufactured Bus Bar Chambers needs very less maintenance</p>
</li> 
<li>
	<span>CONTROL DESK </span>
	<p>Control Desk are manufactured depending upon the space and functionality requirement.</p>
</li> 
<li>
	<span>FEEDER PILLER</span>
	<p>We offer Feeder pillars that are mainly used for housing or installing various equipments or street lighting units. Low-voltage feeder pillars form a basic part of most public and many private distribution networks. Feeder pillars are connected to the distribution board.</p>
</li> 
<li>
	<span>CUSTOMIZED PANEL/ OEM MANUFACTURING-</span>
	<p>INDITRON ELECTRONICS is established as the specialists in designing and manufacturing of customized electrical control panels and power distribution systems for both commercial as well as industrial usage.

Our Companys's core motive is to satisfy our customers with the highest quality power control systems in accordance to their requirements and demands.

We specializes in the planning and execution for Various Types of Trunkey Power Control Projects. We are providing our electrical services to Power Plants, petrochemical, power, fertilizer, cement, steel, aluminum, and other related industries.

As a OEM Control Panel Manufacturer We design & build electrical control panels for customers as per their requirements & demands. We also deals in bulk electrical control panels manufacturing with some of our national and international clients. We can also provide our reliable manufacturing services on contract basis to Government as well as private sector.

You can contact us for bulk, OEM and contract basis, control panels manufacturing as per your specifications and drawings.
ENERGY MANAGEMENT SYSTEMS (EMS SYSTEM) -  INDITRON ELECTRONICS are the manufacturers of Energy Management System / Power Monitoring and Control Software manufacturers in India.
</p>
</li> 

                           </ul>
                           </div>
                         </div>
                    </div>
                  </div>
                </section>
                
            </div>
        );
    }
}

export default SingleElectricalPannel;