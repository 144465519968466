import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Header from '../src/Component/HeaderComponent/Header.js';
import Home from '../src/Component/HomeComponent/Home';
import AboutComponent from '../src/Component/AboutComponent/AboutComponent';
import Chitranshabout from '../src/Component/AboutComponent/Chitranshabout';
import ContactComponent from '../src/Component/ContactComponent/ContactComponent';
import Infrastructure from '../src/Component/InfrastructureComponent/Infrastructure';
import Electricalpanelboard from '../src/Component/ServiceComponent/Electricalpanelboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterComponent from '../src/Component/FooterComponent/FooterComponent';
import ProductComponent from '../src/Component/ProductsComponent/ProductsComponent';
import ClienteleComponent from '../src/Component/ClienteleComponent/ClienteleComponent';
import Manufacturing from '../src/Component/ProductsComponent/Manufacturing';
import ServiceComponent from '../src/Component/ServiceComponent/ServiceComponent';
import GalleryComponent from '../src/Component/GalleryComponent/GalleryComponent';
import Electricalpannel from '../src/Component/ClienteleComponent/SingleComponent/electrical-pannel';
import SingleElectricalPannel from '../src/Component/ProductsComponent/SingleManufacturingComponent/Single-ElectricalPannel';
import Singlewatertreatment from '../src/Component/ProductsComponent/SingleManufacturingComponent/Single-Waterreatmentplant';


import PowerControl from '../src/Component/ProductsComponent/PowerControl';
import MotorControl from '../src/Component/ProductsComponent/MotorControl/MotorControl';
import LowTension  from '../src/Component/ProductsComponent/LowTension/LowTension';
import AutoMaticPower  from '../src/Component/ProductsComponent/AutomaticPower/AutomaticPower';
import PLogicController  from '../src/Component/ProductsComponent/Programmable/Programmable';

import AutoMain from '../src/Component/ProductsComponent/AutoMain/AutoMain';
import VariableFrequency from '../src/Component/ProductsComponent/VariableFrequency/VariableFrequency';
import DgSync from '../src/Component/ProductsComponent/DgSync/DgSync';
import Instrument from '../src/Component/ProductsComponent/Instrument/Instrument';
import FireFighting from '../src/Component/ProductsComponent/FireFighting/FireFighting';
import Busduct from '../src/Component/ProductsComponent/Busduct/Busduct';
import ControlDesk from '../src/Component/ProductsComponent/ControlDesk/ControlDesk';
import FeederPiller from '../src/Component/ProductsComponent/FeederPiller/FeederPiller';
import CustomizedPanel from '../src/Component/ProductsComponent/CustomizedPanel/CustomizedPanel';
class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      statusValue: '1'
    }
  }

  status = (value) => {
    this.setState({
      statusValue: value
    })
  }
  render() {
    return (
      <div className="App" >
        <Router>


          <Header status={this.status} />
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/about" component={AboutComponent} />
          <Route path="/Chitranshabout" component={Chitranshabout} />
          <Route path="/infrastructure" component={Infrastructure} />
          <Route path="/product" component={ProductComponent} />
          <Route path="/manufacturing" component={Manufacturing} />
          <Route path="/services" component={ServiceComponent} />

          <Route path="/clientele" component={ClienteleComponent} />
          <Route path="/gallary" component={GalleryComponent} />
          <Route path="/electricalpanelboard" component={Electricalpanelboard} />
          <Route path="/contact" component={ContactComponent} />
          <Route path="/electrical-pannel" component={Electricalpannel} />
          <Route path="/single-electrical-pannel" component={SingleElectricalPannel} />
          <Route path="/single-water-tertreatment" component={Singlewatertreatment} />

          <Route path="/powercontrol" component={PowerControl} />
          <Route path="/motorcontrol" component={MotorControl} />
          <Route path="/lowtension" component={LowTension} />
          <Route path="/automaticpower" component={AutoMaticPower} />
          <Route path="/plogiccontroller" component={PLogicController} />
         
          
          
          
          <Route path="/autoMain" component={AutoMain} />
          <Route path="/variableFrequency" component={VariableFrequency} />
          <Route path="/dgSync" component={DgSync} />
          <Route path="/instrument" component={Instrument} />
          <Route path="/fireFighting" component={FireFighting} />
          <Route path="/busduct" component={Busduct} />
          <Route path="/controlDesk" component={ControlDesk} />
          <Route path="/feederPiller" component={FeederPiller} />
          <Route path="/customizedPanel " component={CustomizedPanel } />
          

          <FooterComponent statusValue={this.state.statusValue} />

        </Router>


      </div>
    );
  }
}

export default App;
