import React from 'react';
import Profile from '../../assetes/images/profile.jpg';
class ContactComponent extends React.Component{
    
    
    render(){
        
        return(
            <div>
               <section id="banner-section">
                   <div className="container">
                       <div className="row">
                           <div className="col-md-12 banner-heading-div">
                               <p className="banner-heading-text">CONTACT US</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <form method="POST" action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScdDJ6IK5CqqbbkzwP6fRjoivjEcwg53IcJ6OA9LNI12yiSUw/formResponse"  onsubmit="return window.submitGoogleForm(this);">
     
                    <input type='text' name='entry.626695693'  />
                    
                     <button type='submit'>Submit</button>
                </form> */}
                <section className="contact content-area">
                    <div className="container">
                        <div className="row">
                            <div className=" col-lg-4 col-md-5">
                                <div className="contact-info">
                                    <h3 className="heading">Find Us There</h3>
                                    <p>We all at <strong>INDITRON</strong> aspire to <strong>untiringly reinforce this distilled wisdom through continuously anticipating, meeting and exceeding </strong>your<strong> expectations</strong> from us. Thank you for your trust and patronage. It supports us deliver Global, Safe and Reliable Power Distribution Solutions for your customized needs.
</p>
                                    <div className="media">
                                        <i className="fa fa-phone"></i>
                                        <div className="media-body">
                                            <h5 className="mt-0">Phone:</h5>
                                            <p><a href="tel:+91 – 727 – 2259525">+91 – 727 – 2259525</a></p>
                                            <p><a href="tel:+91 – 727 - 2259526">+91 – 727 - 2259526</a></p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <i className="fa fa-envelope-o"></i>
                                        <div className="media-body">
                                            <h5 className="mt-0">Email:</h5>
                                            <p><a href="works@inditron.in">works@inditron.in</a></p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <i className="fa fa-globe"></i>
                                        <div className="media-body">
                                            <h5 className="mt-0">Website:</h5>
                                            <p><a href="https://ieepl.in/">www.ieepl.in
</a></p>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="offset-lg-1 col-lg-7 col-md-7 ">
                                <h3 className="heading">Contact Form</h3>
                                <form action="https://docs.google.com/forms/u/5/d/e/1FAIpQLSfIzLPcPeKjyXoCyjiS_v04Vah7k93D8S4t1qe4oe-C41UYYQ/formResponse"  target="_self" method="POST" id="mG61Hd">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group name">
                                                <input type="text" name="entry.868275706" className="form-control" placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group email">
                                                <input type="email" name="entry.1655609844" className="form-control" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group subject">
                                                <input type="text" name="entry.1021271030" className="form-control" placeholder="Subject"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group number">
                                                <input type="text" name="entry.2103316385" className="form-control" placeholder="Number" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group message">
                                                <textarea className="form-control" name="entry.1565017653" placeholder="Write message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="send-btn">
                                                <button type="submit" className="btn btn-md button-theme">Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="contact-person">
                       
                                <ul className="list-group" >
                    <li className="list-group-item">
                        <div className="row person-inner">
                        <div className="col-md-2">
                            <img src={Profile} className="img-responsive img-circle" />
                        </div>
                        <div className="col-md-10">
                            <div className="person-detail">
                            <span className="name">Mr. C.M. Shrivastava</span><br/>
                            <span className="text-muted">Managing Director</span><br/>
                            <span className="text-muted">91 - 9993576730</span><br/>
                           </div>
                        </div>
                        </div>
                      
                    </li>
                    <li className="list-group-item">
                        <div className="row person-inner">
                        <div className="col-md-2">
                            <img src={Profile} className="img-responsive img-circle" />
                        </div>
                        <div className="col-md-10">
                            <div className="person-detail">
                            <span className="name">Mr. Yogesh Chawrekar</span><br/>
                            <span className="text-muted">Sales Manager</span><br/>
                            <span className="text-muted">91 - 9009770171</span><br/>
                           </div>
                        </div>
                        </div>
                      
                    </li>
                    
                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
    </section>
            </div>
        );
    }
}
export default ContactComponent;
