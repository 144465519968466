import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Gallery from 'react-grid-gallery';

// Control-Panels 1
import Gall1 from '../../assetes/images/gall/Control-Panels/1.jpg';
import Gall2 from '../../assetes/images/gall/Control-Panels/2.jpg';
import Gall3 from '../../assetes/images/gall/Control-Panels/3.jpg';
import Gall4 from '../../assetes/images/gall/Control-Panels/4.jpg';
import Gall5 from '../../assetes/images/gall/Control-Panels/5.jpg';
import Gall6 from '../../assetes/images/gall/Control-Panels/6.jpg';
import Gall7 from '../../assetes/images/gall/Control-Panels/7.jpg';

//Electrical-Erection 2
import Gall8 from '../../assetes/images/gall/Electrical-Erection/8.jpg';
import Gall9 from '../../assetes/images/gall/Electrical-Erection/9.jpg';
import Gall10 from '../../assetes/images/gall/Electrical-Erection/10.jpg';
import Gall11 from '../../assetes/images/gall/Electrical-Erection/11.jpg';
import Gall12 from '../../assetes/images/gall/Electrical-Erection/12.jpg';

//Electrical Panels 3

//Industrial-Projects 4
import Gall13 from '../../assetes/images/gall/Industrial-Projects/13.jpg';
import Gall14 from '../../assetes/images/gall/Industrial-Projects/14.jpg';
import Gall15 from '../../assetes/images/gall/Industrial-Projects/15.jpg';
import Gall16 from '../../assetes/images/gall/Industrial-Projects/16.jpg';
import Gall17 from '../../assetes/images/gall/Industrial-Projects/17.jpg';
import Gall18 from '../../assetes/images/gall/Industrial-Projects/18.jpg';

//Instruments-Erection 5
import Gall19 from '../../assetes/images/gall/Instruments-Erection/19.jpg';
import Gall20 from '../../assetes/images/gall/Instruments-Erection/20.jpg';
import Gall21 from '../../assetes/images/gall/Instruments-Erection/21.jpg';

//Mechanical-fabrication 6
import Gall22 from '../../assetes/images/gall/Mechanical-fabrication/22.jpg';
import Gall23 from '../../assetes/images/gall/Mechanical-fabrication/23.jpg';
import Gall24 from '../../assetes/images/gall/Mechanical-fabrication/24.jpg';
import Gall25 from '../../assetes/images/gall/Mechanical-fabrication/25.jpg';
import Gall26 from '../../assetes/images/gall/Mechanical-fabrication/26.jpg';

//Pretreatment-Plant 7
import Gall27 from '../../assetes/images/gall/Pretreatment-Plant/27.jpg';
import Gall28 from '../../assetes/images/gall/Pretreatment-Plant/28.jpg';
import Gall29 from '../../assetes/images/gall/Pretreatment-Plant/29.jpg';
import Gall30 from '../../assetes/images/gall/Pretreatment-Plant/30.jpg';
import Gall31 from '../../assetes/images/gall/Pretreatment-Plant/31.jpg';
import Gall32 from '../../assetes/images/gall/Pretreatment-Plant/32.jpg';


//Production-area 8

import Gall33 from '../../assetes/images/gall/Production-area/1.jpg';
import Gall34 from '../../assetes/images/gall/Production-area/12.JPG';
import Gall35 from '../../assetes/images/gall/Production-area/14.JPG';
import Gall36 from '../../assetes/images/gall/Production-area/20.JPG';
import Gall37 from '../../assetes/images/gall/Production-area/21.JPG';
import Gall38 from '../../assetes/images/gall/Production-area/DSC_0055.JPG';

// import Gall28 from '../../assetes/images/gall/28.jpg';
// import Gall27 from '../../assetes/images/gall/27.jpg';
// import Gall28 from '../../assetes/images/gall/28.jpg';
//Production-area 9
import Gall39 from '../../assetes/images/gall/WTP/39.jpg';
import Gall40 from '../../assetes/images/gall/WTP/40.jpg';
import Gall41 from '../../assetes/images/gall/WTP/41.jpg';
import Gall42 from '../../assetes/images/gall/WTP/42.jpg';
import Gall43 from '../../assetes/images/gall/WTP/43.jpg';
import Gall44 from '../../assetes/images/gall/WTP/44.jpg';
import Gall45 from '../../assetes/images/gall/WTP/46.jpg';
import Gall46 from '../../assetes/images/gall/WTP/46.jpg';
import Gall47 from '../../assetes/images/gall/WTP/47.jpg';

//OFFice 10
import Gall48 from '../../assetes/images/gall/office/18.JPG';
import Gall49 from '../../assetes/images/gall/office/48.jpg';
import Gall50 from '../../assetes/images/gall/office/49.JPG';
import Gall51 from '../../assetes/images/gall/office/51.jpg';
import Gall52 from '../../assetes/images/gall/office/52.jpg';

//Product 11
import Gall53 from '../../assetes/images/gall/Products/2.jpg';
import Gall54 from '../../assetes/images/gall/Products/8.jpg';
import Gall55 from '../../assetes/images/gall/Products/9.jpg';
import Gall56 from '../../assetes/images/gall/Products/SM BOXES.jpg';
import Gall57 from '../../assetes/images/gall/Products/WP_20140531_004.jpg';
import Gall58 from '../../assetes/images/gall/Products/WP_20140531_007.jpg';

//Event 12
import Gall59 from '../../assetes/images/gall/Misc/6.jpg';
import Gall60 from '../../assetes/images/gall/Misc/22.JPG';
import Gall61 from '../../assetes/images/gall/Misc/27.JPG';
import Gall62 from '../../assetes/images/gall/Misc/28.JPG';
import Gall63 from '../../assetes/images/gall/Misc/29.JPG';
import Gall64 from '../../assetes/images/gall/Misc/30.jpg';
import Gall65 from '../../assetes/images/gall/Misc/31.jpg';
import Gall66 from '../../assetes/images/gall/Misc/32.jpg';
import Gall67 from '../../assetes/images/gall/Misc/33.jpg';
import Gall68 from '../../assetes/images/gall/Misc/40.jpg';
import Gall69 from '../../assetes/images/gall/Misc/41.jpg';
import Gall70 from '../../assetes/images/gall/Misc/20150609_160246.jpg';
import Gall71 from '../../assetes/images/gall/Misc/DSC_1740.jpg';

//Electrical-Panels 3
import Gall72 from '../../assetes/images/gall/Electrical-Panels/7.jpg';
import Gall73 from '../../assetes/images/gall/Electrical-Panels/16.JPG';
import Gall74 from '../../assetes/images/gall/Electrical-Panels/24.JPG';
import Gall75 from '../../assetes/images/gall/Electrical-Panels/25.JPG';
import Gall76 from '../../assetes/images/gall/Electrical-Panels/26.JPG';
import Gall77 from '../../assetes/images/gall/Electrical-Panels/20141017_221420.jpg';
import Gall78 from '../../assetes/images/gall/Electrical-Panels/20150102_204922.jpg';
import Gall79 from '../../assetes/images/gall/Electrical-Panels/20150906_131406.jpg';
import Gall80 from '../../assetes/images/gall/Electrical-Panels/20160308_103815.jpg';
import Gall81 from '../../assetes/images/gall/Electrical-Panels/20160403_095144.jpg';
import Gall82 from '../../assetes/images/gall/Electrical-Panels/20180702_163308_AB Road.jpg';
import Gall83 from '../../assetes/images/gall/Electrical-Panels/34.jpg';
import Gall84 from '../../assetes/images/gall/Electrical-Panels/45.jpg';
import Gall85 from '../../assetes/images/gall/Electrical-Panels/IMG_20181031_161039.jpg';
import Gall86 from '../../assetes/images/gall/Electrical-Panels/IMG_20181221_165841.jpg';
import Gall87 from '../../assetes/images/gall/Electrical-Panels/IMG_20190122_143123.jpg';
import Gall88 from '../../assetes/images/gall/Electrical-Panels/IMG_20190122_143318.jpg';
import Gall89 from '../../assetes/images/gall/Electrical-Panels/IMG_20190522_145936.jpg';
import Gall90 from '../../assetes/images/gall/Electrical-Panels/IMG_20200320_181137.jpg';
import Gall91 from '../../assetes/images/gall/Electrical-Panels/IMG_20200320_181324.jpg';
import Gall92 from '../../assetes/images/gall/Electrical-Panels/46.jpg';




const One =
[{
        src: Gall1,
        thumbnail:Gall1,
        thumbnailWidth: 120,
        thumbnailHeight: 250
      
},
{
    src: Gall2,
    thumbnail:Gall2,
    thumbnailWidth: 420,
    thumbnailHeight: 450
  
},
{
    src: Gall3,
    thumbnail:Gall3,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall4,
    thumbnail:Gall4,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall5,
    thumbnail:Gall5,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall6,
    thumbnail:Gall6,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
,
{
    src: Gall7,
    thumbnail:Gall7,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}

]

const Two =
[{
        src: Gall8,
        thumbnail:Gall8,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall9,
    thumbnail:Gall9,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall10,
    thumbnail:Gall10,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall11,
    thumbnail:Gall11,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall12,
    thumbnail:Gall12,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}


]

const Three =
[{
        src: Gall72,
        thumbnail:Gall72,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall73,
    thumbnail:Gall73,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall74,
    thumbnail:Gall74,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall75,
    thumbnail:Gall75,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall76,
    thumbnail:Gall76,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall77,
    thumbnail:Gall77,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall78,
    thumbnail:Gall78,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall79,
    thumbnail:Gall79,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall80,
    thumbnail:Gall80,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall81,
    thumbnail:Gall81,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall82,
    thumbnail:Gall82,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall83,
    thumbnail:Gall83,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall84,
    thumbnail:Gall84,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall85,
    thumbnail:Gall85,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall86,
    thumbnail:Gall86,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall87,
    thumbnail:Gall87,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall88,
    thumbnail:Gall88,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall89,
    thumbnail:Gall89,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall90,
    thumbnail:Gall90,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall91,
    thumbnail:Gall91,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall92,
    thumbnail:Gall92,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Four =
[{
        src: Gall13,
        thumbnail:Gall13,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall14,
    thumbnail:Gall14,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall15,
    thumbnail:Gall15,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall16,
    thumbnail:Gall16,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall17,
    thumbnail:Gall17,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall18,
    thumbnail:Gall18,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Five =
[{
        src: Gall19,
        thumbnail:Gall9,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall20,
    thumbnail:Gall20,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall21,
    thumbnail:Gall21,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Six =
[{
        src: Gall22,
        thumbnail:Gall22,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall23,
    thumbnail:Gall23,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},{
    src: Gall24,
    thumbnail:Gall24,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
,{
    src: Gall25,
    thumbnail:Gall25,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},{
    src: Gall26,
    thumbnail:Gall26,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Seven =
[{
        src: Gall27,
        thumbnail:Gall27,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall28,
    thumbnail:Gall28,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},{
    src: Gall29,
    thumbnail:Gall29,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},{
    src: Gall30,
    thumbnail:Gall30,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},{
    src: Gall31,
    thumbnail:Gall31,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},{
    src: Gall32,
    thumbnail:Gall32,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Eight =
[{
        src: Gall33,
        thumbnail:Gall33,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall34,
    thumbnail:Gall34,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall35,
    thumbnail:Gall35,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall36,
    thumbnail:Gall36,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall37,
    thumbnail:Gall37,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall38,
    thumbnail:Gall38,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Nine =
[{
        src: Gall39,
        thumbnail:Gall39,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall40,
    thumbnail:Gall40,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall41,
    thumbnail:Gall41,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall42,
    thumbnail:Gall42,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall43,
    thumbnail:Gall43,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall44,
    thumbnail:Gall44,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall45,
    thumbnail:Gall45,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall46,
    thumbnail:Gall46,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall47,
    thumbnail:Gall47,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Ten =
[{
        src: Gall48,
        thumbnail:Gall48,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall49,
    thumbnail:Gall49,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall50,
    thumbnail:Gall50,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall51,
    thumbnail:Gall51,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall52,
    thumbnail:Gall52,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Eleven =
[{
        src: Gall53,
        thumbnail:Gall53,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall54,
    thumbnail:Gall54,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall55,
    thumbnail:Gall55,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall56,
    thumbnail:Gall56,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall57,
    thumbnail:Gall57,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall58,
    thumbnail:Gall58,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
const Twelve =
[{
        src: Gall59,
        thumbnail:Gall59,
        thumbnailWidth: 320,
        thumbnailHeight: 250
      
},
{
    src: Gall60,
    thumbnail:Gall60,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall61,
    thumbnail:Gall61,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall62,
    thumbnail:Gall62,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall63,
    thumbnail:Gall63,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall64,
    thumbnail:Gall64,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall65,
    thumbnail:Gall65,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall66,
    thumbnail:Gall66,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall67,
    thumbnail:Gall67,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall68,
    thumbnail:Gall68,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall69,
    thumbnail:Gall69,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall70,
    thumbnail:Gall70,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
},
{
    src: Gall71,
    thumbnail:Gall71,
    thumbnailWidth: 320,
    thumbnailHeight: 250
  
}
]
class GalleryComponent extends React.Component{
    
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">Gallery</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="gallery-tab-sec">
                    <div className="container">
                      <div className="row">
                          <div className="col-md-12">
                             <div className="gallery-tab-inner">
                             <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                <Nav.Link eventKey="first"><i class="fa fa-sort-desc" aria-hidden="true"></i>Control Panels</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="second"><i class="fa fa-sort-desc" aria-hidden="true"></i>Electrical Erection</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="third"><i class="fa fa-sort-desc" aria-hidden="true"></i>Electrical Panels</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="four"><i class="fa fa-sort-desc" aria-hidden="true"></i>Industrial Projects</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="five"><i class="fa fa-sort-desc" aria-hidden="true"></i>Instruments Erection</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="six"><i class="fa fa-sort-desc" aria-hidden="true"></i>Mechanical fabrication</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="seven"><i class="fa fa-sort-desc" aria-hidden="true"></i>Pretreatment Plant</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="eight"><i class="fa fa-sort-desc" aria-hidden="true"></i>Production Area</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="nine"><i class="fa fa-sort-desc" aria-hidden="true"></i>WTP</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="ten"><i class="fa fa-sort-desc" aria-hidden="true"></i>office</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="eleven"><i class="fa fa-sort-desc" aria-hidden="true"></i>Products</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="twelve"><i class="fa fa-sort-desc" aria-hidden="true"></i>Event</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            </Col>
                            <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                   <Gallery images={One}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                     <Gallery images={Two}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                <Gallery images={Three}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="four">
                                <Gallery images={Four}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="five">
                                <Gallery images={Five}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="six">
                                <Gallery images={Six}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="seven">
                                <Gallery images={Seven}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="eight">
                                <Gallery images={Eight}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="nine">
                                <Gallery images={Nine}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ten">
                                <Gallery images={Ten}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="eleven">
                                <Gallery images={Eleven}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="twelve">
                                <Gallery images={Twelve}/>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                        </Tab.Container>
                             </div>
                          </div>
                      </div>
                    </div>
                </section>
                <section className="g">
                    <div className="container">
                      <div className="row">
                          <div className="col-md-12">
                             <div className="gallery-inner">
                             
                             </div>
                          </div>
                      </div>
                    </div>
                </section>
                
            </div>
        );
    }
}

export default GalleryComponent;