import React from 'react';
import ReactDOM from 'react-dom';
import leftimg from '../../assetes/images/new/chitransh.jpg';
import workerimg from '../../assetes/images/team/worker.png';
import Technicianimg from '../../assetes/images/team/technician.png';
import Filterimg from '../../assetes/images/team/filter.png';
import Welderimg from '../../assetes/images/team/welder.png';
import Staffimg from '../../assetes/images/team/man.png';
import CountUp from 'react-countup';
import Rightimg from '../../assetes/images/right.jpg'

class AboutComponent extends React.Component{
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">ABOUT US</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="welcome-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 welcome-section-2">
                            <img src={leftimg} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                                {/* <img src={leftimg} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" /> */}
                            </div>
                            <div className="col-md-6 welcome-section-1">
                                <h3 className="small-title hps-tw-6 hps-mb-10">Welcome to Our Company</h3>
                                <span className="welcome-section-1-text"><p>We offer a broad spectrum of Electro Mechanical Solutions which have all the core technological resources for the manufacturing and process industries Making use of all the available resources and exploiting the advancement in technologies from different industries, we customize the solutions as per your need. Ours is a one-of-a-kind industry where innovation happens from an objective point of view.
<br/>We provide process-centric solutions which are developed for a specific process alone.
<br/>Our company provided facilities are Planning, Designing, Detailing, Procurement, Manufacturing, Supply, Installation, Testing and commissioning of all kinds of electrical and instrumentation equipments
</p></span>   
                            </div>
                        </div>
                    </div>
                </section>
				<section className="team-sec" >
                    <div className="team-inner">
                       <div className="team-list">
					   <div class="item wow " >
                            <img src={workerimg} />
                           <p id="number1" class="number"><CountUp duration={20} end={10} /></p>
                            <span className="bline"></span>
                            <p>Engineers</p>
                            </div>
							<div class="item wow " >
                            <img src={Technicianimg}/>
                           <p id="number1" class="number"><CountUp duration={20} end={25} /></p>
                            <span className="bline"></span>
                            <p>Technicians</p>
                            </div>
							<div class="item wow " >
                            <img src={Filterimg}/>
                           <p id="number1" class="number"><CountUp duration={20} end={10} /></p>
                            <span className="bline"></span>
                            <p>Fitters</p>
                            </div>
							<div class="item wow " >
                            <img src={Welderimg}/>
                           <p id="number1" class="number"><CountUp duration={20} end={8} /></p>
                            <span className="bline"></span>
                            <p>Welders</p>
                            </div>
							<div class="item wow " >
                            <img src={Staffimg}/>
                           <p id="number1" class="number"><CountUp duration={20} end={10} /></p>
                            <span className="bline"></span>
                            <p>O&M Staff</p>
                            </div>
					   </div>
					</div>
				</section>
                <section class="about-service-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12 home-second-section-inner">
				<div class="main-title-heading">
					<h3 className="small-title hps-tw-6 hps-mb-10">MISSION STATEMENT</h3>
				</div>
			</div>
			<div class="col-md-5 col-sm-5">
				<div class="home-second-img">
					<img src={Rightimg} class="img-responsive" />
				</div>
			</div>
			<div class="col-md-7 col-sm-7">
				<div class="about-service-detail">
					
					<div class="about-service-detail-inner">
						<p><i class="fa fa-low-vision"></i></p>
						<span>
							<h4>Our Mission</h4>
							<p>"With a winning combination of technical expertise and impeccable work ethics, Inditron is committed to achieve sustained growth and explore new horizons."
Our three point approach of "Cutting-Edge Technology"  "Competitive Pricing" and  "Expeditious Delivery" ensures a fruitful, smooth and trustworthy experience for our customers.
</p>
						</span>
					</div>
					<div class="about-service-detail-inner">
						<p><i class="fa fa-check-square-o"></i></p>
						<span>
							<h4>Our Strength</h4>
							<ul>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>Strong Engineering Team</li>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>Ample Work Space with all necessary tools and tackles</li>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>In House manufacturing</li>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>Healthy relationship between customers and suppliers</li>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>Best Grade raw material used for best results</li>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>Competitive Pricing and On time Delivery</li>
								<li><i class="fa fa-sort-desc" aria-hidden="true"></i>We are continuously advancing and upgrading with the new edge Technologies.</li>
							</ul>
							<p>A team of talented and dedicated professionals with broad experience and customer friendly perspective ensures best quality services and guaranteed customer satisfaction.<br/> A vision of achieving the best results without compromising on quality of our products and services has boosted us to the forefront of our industry.</p>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
            </div>
        );
    }
}
export default AboutComponent;