import React from 'react';

import { Link } from 'react-router-dom';
import '../../assetes/css/bootstrap.min.css';
import '../../assetes/css/style.css';
import '../../assetes/css/responsive.css';
import '../../assetes/css/shortcodes.css';
import '../../assetes/css/mobileresponsive.css';


import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import Logo from '../../assetes/images/wlogo.png';

import chitranshlogo from '../../assetes/images/chitranshlogo.png';


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutus: '1'


        }

    }


    handlecheck = (value) => {

        this.setState({
            aboutus: value
        })
        localStorage.setItem('logovalue', value);

        this.props.status(value)
    }



    render() {

        //console.log('local',this.state.aboutus);

        return (
            <div>
                <header className="header-01 re-none">
                    <div className="topbar">
                        <div className="container">
                            {this.state.aboutus == '1' ? <div><div className="row">

                                <div className="col-lg-5 ">
                                    <div className="topbar-left">
                                        <ul className="list-inline">
                                            <li className="list-inline-item"><i className="fa fa-phone text-blue"></i>07272-259525 , 07272-259526</li>
                                            <li className="list-inline-item"><i className="fa fa-envelope-o"> </i> <a href="#" className="__cf_email__" >works@inditron.in</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-7s">
                                    <div className="topbar-right text-right">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">INDITRON ELECTRONICS</li>
                                            <li className="list-inline-item">40-41/3, INDUSTRIAL AREA NO. -1,
A.B. ROAD, DEWAS - 455001, MADHYA PRADESH</li>
                                        </ul>
                                    </div>
                                </div>
                            </div></div> : <div><div className="row">

                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="topbar-left">
                                        <ul className="list-inline">
                                            <li className="list-inline-item"><i className="fa fa-phone text-blue"></i>09329476730</li>
                                            <li className="list-inline-item"><i className="fa fa-envelope-o"> </i> <a href="#" className="__cf_email__" data-cfemail="2d5e585d5d425f596d5c5a444142034e4240">cmshrivastava@rediffmail.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-6">
                                    <div className="topbar-right text-right">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">CHITRANSH ELECTRICAL</li>
                                            <li className="list-inline-item"> 40-41/4, INDUSTRIAL AREA NO. -1, A.B. ROAD, DEWAS - 455001, MADHYA PRADESH</li>
                                        </ul>
                                    </div>
                                </div>
                            </div></div>}

                        </div>
                    </div>
                    <div className="navmenu">
                        <div className="container">
                            <div className="row">
                                <div className="left-sec">
                                    <div className="topbar-left">
                                        <Link onClick={() => this.handlecheck('1')} data-id="1" to="/home"><img src={Logo} /></Link>
                                        <Link className="chitranshlogo cd" onClick={() => this.handlecheck('2')} to="/Chitranshabout" data-id="2" ><img src={chitranshlogo} /></Link>



                                    </div>
                                </div>
                                <div className="right-sec right-menu">
                                    <div className="topbar-right text-right">
                                        <Navbar expand="lg">
                                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                            <Navbar.Collapse id="basic-navbar-nav">
                                                <Nav className="mr-auto">
                                                    {/* <Nav > <Link to="/home" className="nav-link">Home <span className="sr-only">(current)</span> </Link></Nav> */}
                                                    {/* {this.state.aboutus=='1' ?  <Nav><Link to="/about" className="nav-link " >About</Link></Nav>:   <Nav><Link to="/Chitranshabout" className="nav-link">About</Link></Nav>}    */}



                                                    <Nav><Link to="/infrastructure" className="nav-link">Infrastructure</Link></Nav>

                                                    <NavDropdown title="Product" id="basic-nav-dropdown">
                                                        <NavDropdown.Item ><Link to="/powercontrol" className="nav-link">POWER CONTROL CENTER (PCC PANEL)</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/motorcontrol" className="nav-link">MOTOR CONTROL CENTER (MCC PANEL)</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/lowtension" className="nav-link">LOW TENSION PANEL (LT PANEL)</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/automaticpower" className="nav-link">AUTOMATIC POWER FACTOR CORRECTION PANEL (APFC PANEL)-</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/" className="nav-link">MAIN POWER DISTRIUTION BOARD (MPDB PANEL) / MAIN LIGHTING DISTRIUTION BOARD (MLDB PANEL)</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/plogiccontroller" className="nav-link">PROGRAMMABLE LOGIC CONTROLLER PANEL (PLC PANEL)</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/variableFrequency" className="nav-link">VARIABLE FREQUENCY DRIVE PANEL (VFD PANEL) / SOFTSTARTER PANEL</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/autoMain" className="nav-link">AUTO MAINS FAILURE PANEL (AMF PANEL)</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/dgSync" className="nav-link">DG SYNCHRONIZATION PANEL</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/instrument" className="nav-link">INSTRUMENTATION PANEL</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/fireFighting" className="nav-link">FIRE FIGHTING PANEL</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/busduct" className="nav-link">BUSDUCT</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/controlDesk" className="nav-link">CONTROL DESK</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/feederPiller" className="nav-link">FEEDER PILLER</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/customizedPanel" className="nav-link">CUSTOMIZED PANEL/ OEM MANUFACTURING-</Link></NavDropdown.Item>

                                                    </NavDropdown>

                                                    <NavDropdown title="Manufacturing" id="basic-nav-dropdown">
                                                        <NavDropdown.Item ><Link to="/manufacturing" className="nav-link">Manufacturing</Link></NavDropdown.Item>
                                                        <NavDropdown.Item ><Link to="/services" className="nav-link">Services</Link></NavDropdown.Item>
                                                    </NavDropdown>


                                                    <Nav><Link to="/clientele" className="nav-link">Clientele</Link></Nav>
                                                    <Nav><Link to="/gallary" className="nav-link">GALLARY</Link></Nav>

                                                    <div className="top-btn"><Link to="/contact"><button type="button" className="sbtn ">CUSTOMER SUPPORT</button></Link></div>

                                                    {/* <Link className="chitranshlogo cm" onClick={()=>this.handlecheck('2')}  to="/Chitranshabout" data-id="2" ><img src={chitranshlogo}/></Link> */}
                                                </Nav>
                                            </Navbar.Collapse>
                                        </Navbar>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </header>

            </div>
        );
    }
}
export default Header;