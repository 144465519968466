import React from 'react';
import ReactDOM from 'react-dom';


class AboutSection extends React.Component{
    render(){
        return(
            <div>
             <div className="section-block-bg overview-block-ptb">
    <div className="container">
        <div className="heading-title text-center">
            <h2 className="title hps-tw-7">INDITRON ELECTRONICS</h2>

            <p>We provide process-centric solutions which are developed for a specific process alone.</p>
        </div>
        <div className="row mt-40">
            <div className="col-lg-12">
                <div className="plane-box">
                    <div className="plane-box-item"><span className="plane-box-decor"></span>
                        <div className="plane-box-text">
                            <h3>01</h3>
                            <h4>Professional </h4>
                            <p>We are a highly trained and skilled group of professionals that learn every day and apply those lessons in the field. </p>
                        </div>
                    </div>
                    <div className="plane-box-item"><span className="plane-box-decor"></span>
                        <div className="plane-box-text">
                            <h3>02</h3>
                            <h4>Commitment</h4>
                            <p>Once you become our client you’ll have real time eyes to track the progress of the work we do to service your accounts. </p>
                        </div>
                    </div>
                    <div className="plane-box-item"><span className="plane-box-decor"></span>
                        <div className="plane-box-text">
                            <h3>03</h3>
                            <h4>Experience</h4>
                            <p>We have over 50 years of experience in this field and pride ourselves of a good reputation, with honesty, integrity .</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            </div>
        );
    }
}
export default AboutSection;