import React from 'react';
import ReactDOM from 'react-dom';
import InfiniteCarousel from 'react-leaf-carousel';

import Logo1 from '../../assetes/images/logo/logo1.png';
import Logo2 from '../../assetes/images/logo/logo2.png';
import Logo3 from '../../assetes/images/logo/logo3.png';
import Logo4 from '../../assetes/images/logo/logo4.png';
import Logo5 from '../../assetes/images/logo/logo5.png';
import Logo6 from '../../assetes/images/logo/logo6.png';
import Logo7 from '../../assetes/images/logo/logo7.png';
import Logo8 from '../../assetes/images/logo/logo8.png';
import Logo9 from '../../assetes/images/logo/logo9.png';
import Logo10 from '../../assetes/images/logo/logo10.png';
import Logo11 from '../../assetes/images/logo/logo11.png';
import Logo12 from '../../assetes/images/logo/logo12.png';
import Logo13 from '../../assetes/images/logo/logo13.png';
import Logo14 from '../../assetes/images/logo/logo14.png';
import Logo15 from '../../assetes/images/logo/logo15.png';

class LogoComponent extends React.Component{
    render(){
        return(
          <div>
              <section className="logo-sec">
                  <div className="container">
                      <div className="row">
                   
                      <InfiniteCarousel
                            breakpoints={[
                            {
                                breakpoint: 500,
                                settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                },
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                },
                            },
                            ]}
                            dots={true}
                            showSides={true}
                            sidesOpacity={.5}
                            sideSize={.1}
                            slidesToScroll={6}
                            slidesToShow={6}
                            scrollOnDevice={true}
                        >
                            <div>
                            <img
                                alt=''
                                src={Logo1}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo2}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo3}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo4}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo5}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo6}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo7}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo8}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo9}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo10}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo11}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo12}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo13}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo14}
                            />
                            </div>
                            <div>
                            <img
                                alt=''
                                src={Logo15}
                            />
                            </div>
                        
                        
                        </InfiniteCarousel>
                      </div>
                  </div>
              </section>
          </div>
        );
    }
}

export default LogoComponent;