import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CustomizedPanel.css'
class CustomizedPanel extends React.Component {
    render() {
        return (
            <div>
              CustomizedPanel
            </div>
        );
    }
}

export default CustomizedPanel;