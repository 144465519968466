import React from 'react';
import ReactDOM from 'react-dom';
import leftimg from '../../assetes/images/service1.png';

class Electricalpanelboard extends React.Component{
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 banner-heading-div">
                                <p class="banner-heading-text">ABOUT US</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="welcome-section" >
    <div class="container">
        <div class="row">
            <div class="col-md-6 welcome-section-2">
                <img src={leftimg} alt="img" class="img-responsive img-thumbnail welcome-section-2-img" />
            </div>
            <div class="col-md-6 welcome-section-1">
                <h3 class="small-title hps-tw-6 hps-mb-10">Electrical Panel Board</h3>
                <span class="welcome-section-1-text"><p>Due to our more than 20 years of experience in the HVAC/R industry, we’ve developed strong relationships with some of the top contractors in the GTA area. </p></span>   
            </div>
        </div>
    </div>
</section>
            </div>
        );
    }
}
export default Electricalpanelboard;

