import React from 'react';
import ReactDOM from 'react-dom';
import Carousel from 'react-bootstrap/Carousel';
import banner1 from '../../assetes/images/banner1.png';
import banner2 from '../../assetes/images/banner2.png';
import banner3 from '../../assetes/images/new/slide3.jpg';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Slider extends React.Component{
    render(){
        return(
            <div className="slidersection"> 
              <Carousel>
  <Carousel.Item className="first-slider">
  <div class="overlay"></div>
    <Carousel.Caption>
      <h3>ONE ROOF,  MANY SOLUTIONS</h3>
      
      {/* <Button className="sbtn"><Link to="/about">Read More</Link></Button> */}
    </Carousel.Caption>
  </Carousel.Item>
  {/* <Carousel.Item className="second-slider">
  <div class="overlay"></div>
    <Carousel.Caption>
      <h3>ONE ROOF,  MANY SOLUTIONS</h3>
      
      
    </Carousel.Caption>
  </Carousel.Item> */}
  {/* <Carousel.Item className="third-slider"> 
  <div class="overlay"></div>
    <Carousel.Caption>
      <h3>WELCOME TO Chitransh Electrical</h3>
      
      <Button  className="sbtn"><Link to="/Chitranshabout">Read More</Link></Button>
    </Carousel.Caption>
  </Carousel.Item> */}
</Carousel>

            </div>
        );
    }
}
export default Slider;