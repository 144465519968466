import React from 'react';

import { Tab, Row, Col, Nav} from 'react-bootstrap';
import '../ClienteleComponent/ClienteleComponent.css';
import Epanel from '../../assetes/images/elect.jpg';
import TURNKEY from '../../assetes/images/turnkey.png';
import Logo1 from '../../assetes/images/logo/logo1.png';
import Logo2 from '../../assetes/images/logo/logo2.png';
import Logo3 from '../../assetes/images/logo/logo3.png';
import Logo4 from '../../assetes/images/logo/logo4.png';
import Logo5 from '../../assetes/images/logo/logo5.png';
import Logo6 from '../../assetes/images/logo/logo6.png';
import Logo7 from '../../assetes/images/logo/logo7.png';
import Logo8 from '../../assetes/images/logo/logo8.png';
import Logo9 from '../../assetes/images/logo/logo9.png';
import Logo10 from '../../assetes/images/logo/logo10.png';
import Logo11 from '../../assetes/images/logo/logo11.png';
import Logo12 from '../../assetes/images/logo/logo12.png';
import Logo13 from '../../assetes/images/logo/logo13.png';
import Logo14 from '../../assetes/images/logo/logo14.png';
import Logo15 from '../../assetes/images/logo/logo15.png';
import Logo16 from '../../assetes/images/logo/logo16.png';
import Logo17 from '../../assetes/images/logo/logo17.png';
import Logo18 from '../../assetes/images/logo/logo18.png';
import Logo19 from '../../assetes/images/logo/logo19.png';
import Logo20 from '../../assetes/images/logo/logo20.png';
import Logo21 from '../../assetes/images/logo/logo21.png';
import Logo22 from '../../assetes/images/logo/logo22.png';
import Logo23 from '../../assetes/images/logo/logo23.png';
import Logo24 from '../../assetes/images/logo/logo24.png';
import Logo25 from '../../assetes/images/logo/logo25.png';
import Logo26 from '../../assetes/images/logo/logo26.png';
import Logo27 from '../../assetes/images/logo/logo27.png';
import Logo28 from '../../assetes/images/logo/logo28.png';
import Logo29 from '../../assetes/images/logo/logo29.png';
import Logo30 from '../../assetes/images/logo/logo30.png';
import Logo31 from '../../assetes/images/logo/logo31.png';
import Logo32 from '../../assetes/images/logo/logo32.png';
import Logo33 from '../../assetes/images/logo/logo33.png';
import Logo34 from '../../assetes/images/logo/logo34.png';
import Logo35 from '../../assetes/images/logo/logo35.png';
import Logo36 from '../../assetes/images/logo/logo36.png';
import Logo37 from '../../assetes/images/logo/logo37.png';
import Logo38 from '../../assetes/images/logo/logo38.png';
import Logo39 from '../../assetes/images/logo/logo39.png';
import Logo40 from '../../assetes/images/logo/logo40.png';
import Logo41 from '../../assetes/images/logo/logo41.png';
import Logo42 from '../../assetes/images/logo/logo42.png';
import Logo43 from '../../assetes/images/logo/logo43.png';


class ClienteleComponent extends React.Component{
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">Clientele</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="project-list">
                    <div class="container">
                        <div class="row">
                           <div className="logo-filter">
                           <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={12}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                        <Nav.Link eventKey="first"> ELECTRICAL PANEL</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="second">TURNKEY PROJECTS</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="third">WATER TREATMENT PLANT</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="four">OPERATION & MAINTENANCE</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="five">PROJECT CONSULTANT</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                        <Nav.Link eventKey="six">SERVICE & AMC</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content  className="logo-img">
                                            <Tab.Pane eventKey="first">
                                                <div className="row">
                                                    <ul className="logo-list-item">
                                                        <li><img src={Logo1}/></li>
                                                        <li><img src={Logo2}/></li>
                                                        <li><img src={Logo3}/></li>
                                                        <li><img src={Logo4}/></li>
                                                        <li><img src={Logo5}/></li>
                                                        <li><img src={Logo6}/></li>
                                                        <li><img src={Logo7}/></li>
                                                        <li><img src={Logo8}/></li>
                                                        <li><img src={Logo9}/></li>
                                                        <li><img src={Logo10}/></li>
                                                        <li><img src={Logo11}/></li>
                                                        <li><img src={Logo12}/></li>
                                                        <li><img src={Logo13}/></li>
                                                        <li><img src={Logo14}/></li>
                                                        <li><img src={Logo15}/></li>
                                                        <li><img src={Logo16}/></li>
                                                        <li><img src={Logo17}/></li>
                                                        <li><img src={Logo40}/></li>
                                                        <li><img src={Logo30}/></li>
                                                        <li><img src={Logo31}/></li>
                                                        <li><img src={Logo42}/></li>
                                                        <li><img src={Logo43}/></li>
                                                        
                                                    </ul>  
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="row">
                                                    <ul className="logo-list-item">
                                                        <li><img src={Logo18}/></li>
                                                        <li><img src={Logo19}/></li>
                                                        <li><img src={Logo20}/></li>
                                                        <li><img src={Logo21}/></li>
                                                        <li><img src={Logo22}/></li>
                                                        <li><img src={Logo23}/></li>
                                                        <li><img src={Logo42}/></li>
                                                        <li><img src={Logo43}/></li>
                                                        
                                                    </ul>  
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <div className="row">
                                                    <ul className="logo-list-item">
                                                        <li><img src={Logo24}/></li>
                                                        <li><img src={Logo25}/></li>
                                                        <li><img src={Logo26}/></li>
                                                        <li><img src={Logo27}/></li>
                                                        <li><img src={Logo28}/></li>
                                                       
                                                        
                                                    </ul>  
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="four">
                                                <div className="row">
                                                    <ul className="logo-list-item">
                                                        <li><img src={Logo29}/></li>
                                                        <li><img src={Logo30}/></li>
                                                        <li><img src={Logo31}/></li>
                                                        <li><img src={Logo32}/></li>
                                                        <li><img src={Logo33}/></li>
                                                        <li><img src={Logo34}/></li>
                                                        <li><img src={Logo35}/></li>
                                                        <li><img src={Logo36}/></li>
                                                        <li><img src={Logo37}/></li>
                                                        <li><img src={Logo38}/></li>
                                                        <li><img src={Logo39}/></li>
                                                        <li><img src={Logo40}/></li>
                                                        <li><img src={Logo41}/></li>
                                                       
                                                    </ul>  
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="five">
                                                <div className="row">
                                                    <ul className="logo-list-item">
                                                        <li><img src={Logo30}/></li>
                                                        <li><img src={Logo42}/></li>
                                                        <li><img src={Logo43}/></li>
                                                     </ul>  
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="six">
                                                <div className="row">
                                                    <ul className="logo-list-item">
                                                      
                                                        
                                                     </ul>  
                                                </div>
                                            </Tab.Pane>
                                    </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                           </div>
                        </div>
                    </div>
                </section>
                
            </div>
        );
    }
}

export default ClienteleComponent;