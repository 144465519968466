import React from 'react';
import { Link } from 'react-router-dom';
import pdf from '../../../src/assetes/images/pdf/Website-pdf.pptx';



class FooterComponent extends React.Component{

    constructor(props){
        super(props)
    }
    render(){
        console.log('use this pankaj',this.props.statusValue)
        return(
            <div>
                <section id="content-inner-about" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="inner-about-sub">
                                    <div className="video-main-inner-content" >
                                    <h4>We Are Here !</h4>
                                     
                                     <Link to="/contact" className="nav-link">Contact</Link>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.props.statusValue=='1' ? <div> <footer className="hps-footer3">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row overview-block-ptb4">
                                <div className="col-lg-4 col-md-12 col-sm-12 hps-mtb-15">
                                <div className="link">
                                       <h5 className="small-title hps-tw-6 hps-font-black hps-mb-20">Download Center</h5>
                                       {/* <div className="hps-font-black hps-mt-15">Lorem Ipsum is a building integration company, specializing in designing, servicing, maintaining, and installing all building security &amp; life safety equipments in the new york city, long island, and greater nyc area.</div>
                                        */}
                                        <button className="dbtn"><a  href={pdf}>Downloads</a></button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 hps-mtb-15 menu hps-re-4-mt50">
                                <div className="link">
                                    <h5 className="small-title hps-tw-6 hps-font-black hps-mb-20">Contact</h5>
                                </div>
                                    <div className="hps-contact-box hps-mb-20">
                                        <div className="hps-icon">
                                            <i aria-hidden="true" className="fa fa-phone text-blue"></i>
                                        </div>
                                        <div className="hps-content">
                                           <div className="hps-lead hps-font-black hps-tw-6">Phone</div>
                                           
                                           <span className="hps-tw-6">07272-259525</span><br/>
                                           <span className="hps-tw-6">07272-259526</span>
                                        </div>
                                    </div>
                                    <div className="hps-contact-box hps-mb-20">
                                        <div className="hps-icon">
                                           <i aria-hidden="true" className="fa fa-envelope-o"></i>
                                        </div>
                                        <div className="hps-content">
                                        <div className="hps-lead hps-font-black hps-tw-6">Mail</div>
                                        
                                        <span className="hps-tw-6">works@inditron.in</span>
                                        </div>
                                   </div>
                                   <div className="hps-contact-box hps-mb-20">
                                        <div className="hps-icon">
                                        <i aria-hidden="true" className="fa fa-map-marker"></i>
                                        </div>
                                        <div className="hps-content">
                                        <div className="hps-lead hps-font-black hps-tw-6">Address</div>
                                        <span className="hps-tw-6">40-41/3, INDUSTRIAL AREA NO. -1,
A.B. ROAD, DEWAS - 455001, MADHYA PRADESH</span>
                                        </div>
                                    </div>
                                    <h4 className="flu">FOLLOW US ON</h4>
                                    <ul className="hps-media-blog">
                                        <li><a target="_blank" href="https://m.facebook.com/story.php?story_fbid=107383237637326&id=107367297638920"><i className="fa fa-facebook"></i></a></li>
                                        <li><a target="_blank" href="#"><i className="fa fa-twitter"></i></a></li>
                                        
                                    </ul>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12 hps-mtb-15 hps-re-9-mt50">
                                <div className="link">
                                        <h5 className="small-title hps-tw-6 hps-font-black hps-mb-20">Site Map</h5>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14695.684420985626!2d76.03755!3d22.9531325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963177f122d5b37%3A0x64777b4343df0c06!2sInditron%20Electronics!5e0!3m2!1sen!2sin!4v1587746601058!5m2!1sen!2sin" width="600" height="450" ></iframe>
                                    </div>
                         
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row overview-block-ptb4 text-center">
                                <div className="col-sm-12">
                                <div className="hps-copyright">Copyright <span id="copyright"> 2020</span>All Rights Reserved </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </footer></div>:   <div> <footer className="hps-footer3">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row overview-block-ptb4">
                                <div className="col-lg-4 col-md-12 col-sm-12 hps-mtb-15">
                                <div className="link">
                                       <h5 className="small-title hps-tw-6 hps-font-black hps-mb-20">Download Center</h5>
                                       {/* <div className="hps-font-black hps-mt-15">Lorem Ipsum is a building integration company, specializing in designing, servicing, maintaining, and installing all building security &amp; life safety equipments in the new york city, long island, and greater nyc area.</div>
                                        */}
                                        <button className="dbtn"><a  href={pdf}>Downloads</a></button>   </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 hps-mtb-15 menu hps-re-4-mt50">
                                <div className="link">
                                    <h5 className="small-title hps-tw-6 hps-font-black hps-mb-20">Contact</h5>
                                </div>
                                    <div className="hps-contact-box hps-mb-20">
                                        <div className="hps-icon">
                                            <i aria-hidden="true" className="fa fa-phone text-blue"></i>
                                        </div>
                                        <div className="hps-content">
                                           <div className="hps-lead hps-font-black hps-tw-6">Phone</div>
                                           <span className="hps-tw-6">09329476730</span>
                                        </div>
                                    </div>
                                    <div className="hps-contact-box hps-mb-20">
                                        <div className="hps-icon">
                                           <i aria-hidden="true" className="fa fa-envelope-o"></i>
                                        </div>
                                        <div className="hps-content">
                                        <div className="hps-lead hps-font-black hps-tw-6">Mail</div>
                                        <span className="hps-tw-6">cmshrivastava@rediffmail.com</span>
                                        </div>
                                   </div>
                                   <div className="hps-contact-box hps-mb-20">
                                        <div className="hps-icon">
                                        <i aria-hidden="true" className="fa fa-map-marker"></i>
                                        </div>
                                        <div className="hps-content">
                                        <div className="hps-lead hps-font-black hps-tw-6">Address</div>
                                        <span className="hps-tw-6">40-41/4, INDUSTRIAL AREA NO. -1,
A.B. ROAD, DEWAS - 455001, MADHYA PRADESH</span>
                                        </div>
                                    </div>
                                    <h4 className="flu">FOLLOW US ON</h4>
                                    <ul className="hps-media-blog">
                                        <li><a target="_blank" href="https://m.facebook.com/story.php?story_fbid=107383237637326&id=107367297638920"><i className="fa fa-facebook"></i></a></li>
                                        <li><a target="_blank" href="#"><i className="fa fa-twitter"></i></a></li>
                                        
                                    </ul>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12 hps-mtb-15 hps-re-9-mt50">
                                <div className="link">
                                        <h5 className="small-title hps-tw-6 hps-font-black hps-mb-20">Site Map</h5>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14695.684420985626!2d76.03755!3d22.9531325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963177f122d5b37%3A0x64777b4343df0c06!2sInditron%20Electronics!5e0!3m2!1sen!2sin!4v1587746601058!5m2!1sen!2sin" width="600" height="450" ></iframe>
                                    </div>
                         
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row overview-block-ptb4 text-center">
                                <div className="col-sm-12">
                                <div className="hps-copyright">Copyright <span id="copyright"> 2020</span>All Rights Reserved </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </footer></div>} 
               
            </div>
        );
    }
}
export default FooterComponent;