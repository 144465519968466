import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import VFDPANEL from '../../../assetes/images/vfd/VFDPANEL.jpg';
class VariableFrequency extends React.Component {
    render() {
        return (
            <div>
            <section id="banner-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 banner-heading-div">
                            <p className="banner-heading-text">VARIABLE FREQUENCY DRIVE PANEL (VFD PANEL) / SOFTSTARTER PANEL</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">
                                <img src={VFDPANEL} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="man-content">

                            <p>VFD Panel or variable frequency Drive is widely used in the speed control application of the heavy duty motors. Their main function is to regulate the speed of the motor or pump. We are offering our products range in different specifications in order to meet the diverse requirements of the industry. Banking on our innovative approach, we have used cutting edge technology while manufacturing Electrical VFD panels. Our experienced team has used graded raw materials to manufacture our products to ensure robust construction. The high strength is provided so that these can withstand tough working conditions.</p>
                        </div>

                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div >
                                <table>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Readymade as per customer specification for various type of industries, Indoor or outdoor metering, control,protection,relay & automation for LV panel PLC & RELAY Based</td>

                                    </tr>
                                    <tr>
                                        <th>System</th>
                                        <td>3 Phase 3 or 4 wire, 50Hz</td>

                                    </tr>
                                    <tr>
                                        <th>Rated Current</th>
                                        <td>Upto 4000 Amps</td>
                                    </tr>
                                    <tr>
                                        <th>Operational Voltage</th>
                                        <td>24/110/230v 415v AC</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Insulation</th>
                                        <td>1000v</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Service Voltage</th>
                                        <td>690v</td>
                                    </tr>
                                    <tr>
                                        <th>Ambient Temperature</th>
                                        <td>45Deg C</td>
                                    </tr>
                                    <tr>
                                        <th>Short Time Current</th>
                                        <td>50KA for 1Sec,105KA peak</td>
                                    </tr>
                                    <tr>
                                        <th>Degree of Protection</th>
                                        <td>IP42,54,55,65 as per Custom Requirement</td>
                                    </tr>
                                    <tr>
                                        <th>Form of sepration</th>
                                        <td>Upto 3B/4B(Optional Type 5)</td>
                                    </tr>
                                    <tr>
                                        <th>Type Tested for</th>
                                        <td>Short Time Current withstand Temperature Rise Degree of Protection</td>
                                    </tr>
                                    <tr>
                                        <th>Bus Bar</th>
                                        <td>Aluminium or Copper</td>
                                    </tr>
                                    <tr>
                                        <th>Ref.Standard</th>
                                        <td>IEC60439-1&2, IS:8623</td>
                                    </tr>
                                    <tr>
                                        <th>Feeder Configuration</th>
                                        <td>Drowout or Fixed,Extendable Type</td>
                                    </tr>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
        );
    }
}

export default VariableFrequency;