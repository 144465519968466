import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './FeederPiller.css'
class FeederPiller extends React.Component {
    render() {
        return (
            <div>
              FeederPiller
            </div>
        );
    }
}

export default FeederPiller;