import React from 'react';
import ReactDOM from 'react-dom';
import '../ProductsComponent/Productcomponent.css';
import { Tab, Row, Col, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Manufacturing extends React.Component{
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">Manufacturing</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                         <div className="row">
                             <div className="col-md-12 ">
                                 <div className="man-content">
                                   
                                 <p>We, INDITRON ELECTRONICS, is a fast growing firm in electrical field, for manufacturing Power and Control Distribution Boards. We specialize in range of Power and Control Panels with for all kind of industries. We have full facility for testing various types of Distribution Boards. We have professionally managed team to meet most stringent quality standard to cater our client’s requirements</p>
                                 <p>Since our establishment, we INDITRON ELECTRONICS have emerged as India’s one of the most prominent manufacturers, suppliers, and service providers of a comprehensive range Electrical Control Panels. Our products are manufactured by using quality basic material and environment friendly processing techniques. We also provide customize control panel solutions to our customers as per their specifications</p>
                                 </div>
                                  
                             </div>
                         </div>
                     </div>
                </section>
                <section className="bg-sec">
                  <div className="container">
                    <div className="bg-inner-sec">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={4}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                <Nav.Link eventKey="first"><i class="fa fa-sort-desc" aria-hidden="true"></i> ELECTRICAL PANEL</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="second"><i class="fa fa-sort-desc" aria-hidden="true"></i>TURNKEY PROJECT</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="third"><i class="fa fa-sort-desc" aria-hidden="true"></i> WATER TREATMENT PLANT</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            </Col>
                            <Col sm={8}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                               <p>Our range of automatic electrical control panel products are known for its reliable approach, efficiency, & excellence and thus, are utilized by industries such as Chemical, Textile, Petrochemical, Pharmaceuticals, Heavy Metal, Food Processing, and Water Treatment, Shopping Malls & Plaza, Refineries, Oil Mills, Iron & Steel, Pump Houses, and Sewage Plants.</p>
                               
                                <Link to="single-electrical-pannel">Read More</Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                <p></p> 
                                <div className="governance-main-inner">
    <ul>
		<li>
		    <span>INDUSTRIAL PROJECTS </span>
		    <p>Engineering, Procurements and Execution of Industrial Projects for various Engineering Plants, Cement Plants, Chemical Plants, Infra Structure Projects.</p>
		</li>
		<li>
		    <span>SUB-STATIONS </span>
		    <p>Procurement and Installation of Transformer Sub-Stations, Distribution Transformer Centres for various Power Distribution Purpose for Industries, Townships etc.</p>
		</li> 
		<li>
		    <span>OVERHEAD LINES</span>
		    <p>Procurement and Installation of Overhead Power Distribution Lines 11 KV, 22KV, 33KV for energy transmission purpose.</p>
		</li> 
		<li>
		    <span>PLANT MAINTENANCE </span>
		    <p>Plant Electrical Maintenance in Annual Contract Basis for heavy Steel Plant, Cement Plant, Aluminum Plant inclusive of HT & LT Distributions and Plant Illumination systems.</p>
		</li> 
		<li>
		    <span>LIAISON WORKS</span>
		    <p>Power Sanction and Power Release form various Govt. And Semi Govt. Power Supply Providers.</p>
		</li> 
		<li>
		    <span>CONTROL & INSTRUMENTATION</span>
		    <p>Erection, Testing and Commissioning of Instrumentation systems for Power, Steel, Cement and other Process Industries.Erection, Testing and Commissioning of Instrumentation systems for Power, Steel, Cement and other Process Industries.</p>
		</li> 
		<li>
		    <span>STRUCTURAL FABRICATION</span>
		    <p>Fabrication & Installation of Structure, Pipe Gallery, Conveyor structure, Cable Trestle, Building Substation, Color Coated Sheeting</p>
		</li> 
    </ul>
</div>



                               
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                <p>We at Fluid Systems are engaged in manufacturing and supplying a wide range of Water Treatment Plants, Sewage Treatment Plants, Effluent Treatment Plants and Packaged Water Treatment Plants. Apart from these we also offer package sewage treatment, combined sewage cum food waste treatment plants and combined sewage cum effluent treatment plants. We also offer effective turnkey solutions for these plants to our clients. Our entire range of plants and services are widely demanded in pharmaceutical industry, chemical manufacturing industry and food processing industry to name a few.</p> 
                                <Link to="single-water-tertreatment">Read More</Link>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                        </Tab.Container>
                    </div>
                  </div>
                </section>
            </div>
        );
    }
}

export default Manufacturing;