import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Ourimage from '../../assetes/images/new/about.jpg';
// import Rightimg from '../../assetes/images/right.jpg'
import Rightimg from '../../assetes/images/new/EPC.jpg'


class OurSection extends React.Component {

    render() {

        return (
            <div>
                <section className="overview-block-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 text-center">
                                <div className="popup-gallery play-video2 hps-pos-r hps-mtb-10">
                                    <div className="video-img"> <img alt="#" src={Ourimage} className="img-fluid" /> </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="small-title hps-tw-6 hps-mb-10">Company Profile</h3>
                                {/* {this.state.aboutus=='1' ? <p>Inditron</p> :  <p>chotransh</p>} */}
                                <p>

                                    We  at "Inditron" are engaged in providing state of the art solutions in the field of all kinds of electrical and automation solutions along with in house manufacturing facility for all types of electrical panels.
                                    
                                    Since our inception in 2008, we are on the path of excellence and reliability, reaching out to be the premier company in this sector.
</p>
                                {/* <button className="theme_button color1"><Link to="/about">Read More</Link></button> */}
                            </div>

                        </div>
                    </div>
                </section>
                <section class="about-service-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 home-second-section-inner">
                                <div class="main-title-heading">
                                    <h3 className="small-title hps-tw-6 hps-mb-10">MISSION STATEMENT</h3>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-5">
                                <div class="home-second-img">
                                    <img src={Rightimg} class="img-responsive" />
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-7">
                                <div class="about-service-detail">

                                    <div class="about-service-detail-inner">
                                        <p><i class="fa fa-low-vision"></i></p>
                                        <span>
                                            <h4>Our Mission</h4>
                                            <p>"With a winning combination of technical expertise and impeccable work ethics, Inditron is committed to achieve sustained growth and explore new horizons."
                Our three point approach of "Cutting-Edge Technology"  "Competitive Pricing" and  "Expeditious Delivery" ensures a fruitful, smooth and trustworthy experience for our customers.
</p>
                                        </span>
                                    </div>
                                    <div class="about-service-detail-inner">
                                        <p><i class="fa fa-check-square-o"></i></p>
                                        <span>
                                            <h4>Our Strength</h4>
                                            <ul>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>Strong Engineering Team</li>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>Ample Work Space with all necessary tools and tackles</li>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>In House manufacturing</li>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>Healthy relationship between customers and suppliers</li>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>Best Grade raw material used for best results</li>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>Competitive Pricing and On time Delivery</li>
                                                <li><i class="fa fa-sort-desc" aria-hidden="true"></i>We are continuously advancing and upgrading with the new edge Technologies.</li>
                                            </ul>
                                            <p>A team of talented and dedicated professionals with broad experience and customer friendly perspective ensures best quality services and guaranteed customer satisfaction.<br /> A vision of achieving the best results without compromising on quality of our products and services has boosted us to the forefront of our industry.</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default OurSection;