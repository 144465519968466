import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ControlDesk.css'
class ControlDesk extends React.Component {
    render() {
        return (
            <div>
              AutomaticPower
            </div>
        );
    }
}

export default ControlDesk;