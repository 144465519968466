import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PLCPANEL from '../../../assetes/images/plc/PLCPANEL(1).jpg';
import PLCPANEL1 from '../../../assetes/images/plc/PLCPANEL(2).jpg';
import './Programmable.css'


class Programmable extends React.Component {
    render() {
        return (
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">PROGRAMMABLE LOGIC CONTROLLER PANEL (PLC PANEL)</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="man-content">
                                    <img src={PLCPANEL} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                 <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="man-content">
                                    <img src={PLCPANEL1} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">

                                <p>PLC automation panels or programmable logic controller (PLC) is the most effective choice widely used for electronic and electrical circuit fittings. As the leading PLC panel manufacturers and suppliers in India, our panels have the capability to give excellent output at low power consumption. Our PLC panels are well integrated with effective consulting and programming services for cutting edge remote communications. Because of compact size and solid PLC logic, our PLC panels are taken as highly valuable over conventional relay based systems.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Programmable;