import React from 'react';
import ReactDOM from 'react-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import APFCPANEL from '../../../assetes/images/apfc/APFCPANEL.jpg';

import './AutomaticPower.css'
class AutomaticPower extends React.Component {
    render() {
        return (
            <div>
            <section id="banner-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 banner-heading-div">
                            <p className="banner-heading-text">AUTOMATIC POWER FACTOR CORRECTION PANEL (APFC PANEL)</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="man-content">
                                <img src={APFCPANEL} alt="img" className="img-responsive img-thumbnail welcome-section-2-img" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="man-content">

                            <p>An Automatic Power Factor Correction (Controller) panel known as APFC panel is used to Save Energy by consistently maintaining higher power factor. Low Power Factor leads to poor power efficiency, thereby increasing the apparent power drawn from the distribution network. This result in overloading of distribution devices within the Industry. Our APFC Panels are a modern Powerful System constituting a Technological Breakthrough in the field of Power Factor Control. Our Automatic Power Factor Correction Panel is ready to connect, for the central compensation of reactive power in three-phase Supply Systems for Steel Rolling Mills, Cement Plants, Engg. Industries, Commercial Buildings etc.</p>
                        </div>

                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div >
                                <table>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Automatic Power factor correction equipment, turned capacitor banks, dynamic PFC</td>

                                    </tr>
                                    <tr>
                                        <th>System</th>
                                        <td>415V 3 Phase, 3 or 4 wire, 50HZ</td>

                                    </tr>
                                    <tr>
                                        <th>Rated Power</th>
                                        <td>Upto 1200 KVAR</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Voltage Capacitore</th>
                                        <td>230/525v</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Insulation</th>
                                        <td>1000v</td>
                                    </tr>
                                    <tr>
                                        <th>Rated Service Voltage</th>
                                        <td>690v</td>
                                    </tr>
                                    <tr>
                                        <th>Ambient Temperature</th>
                                        <td>45Deg C</td>
                                    </tr>
                                    <tr>
                                        <th>Short Time Current</th>
                                        <td>50KA for 1Sec,105KA peak</td>
                                    </tr>
                                    <tr>
                                        <th>Degree of Protection</th>
                                        <td>IP42,54,55,65 as per Custom Requirement</td>
                                    </tr>
                                    <tr>
                                        <th>Form of sepration</th>
                                        <td>Upto 3B/4B</td>
                                    </tr>
                                    <tr>
                                        <th>Type Tested for</th>
                                        <td>Short Time Current withstand Temperature Rise Degree of Protection</td>
                                    </tr>
                                    <tr>
                                        <th>Bus Bar</th>
                                        <td>Aluminium or Copper</td>
                                    </tr>

                                    <tr>
                                        <th>Feeder Configuration</th>
                                        <td>Drowout or Fixed,Extendable Type</td>
                                    </tr>
                                    <tr>
                                        <th>Colling</th>
                                        <td>Naturak/Forced</td>
                                    </tr>
                                    <tr>
                                        <th>De-tuning</th>
                                        <td>Conventional or5.67%-7% or 14%</td>
                                    </tr>
                                    <tr>
                                        <th>Switching</th>
                                        <td>Contactor/Thysistor</td>
                                    </tr>
                                    <tr>
                                        <th>PF Control</th>
                                        <td>Both manual and Automation through APFC relay upto 20 stage</td>
                                    </tr>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
        );
    }
}

export default AutomaticPower;