import React from 'react';
import ReactDOM from 'react-dom';

import Repairimg from '../../assetes/images/repair.png';
import Porjectimg from '../../assetes/images/hr.png';
import Serviceimg from '../../assetes/images/support.png';
import '../ProductsComponent/Productcomponent.css';
class ServiceComponent extends React.Component{
    render(){
        return(
            <div>
                <section id="banner-section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 banner-heading-div">
                                <p className="banner-heading-text">Service</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <div className="col-md-12 ">
                                 <div className="man-content">
                                   
                                 <p>Our team consists of experienced and knowledgeable professionals, cross-trained to manage numerous power plant start-up and commissioning services. The team members possess extensive field experience in the start-up and commissioning of power generation facilities. They are highly trained in their particular field of expertise.
Our proven experience in start-up and commissioning worldwide will deliver your facility on schedule and within budget using the highest industry standards. Our technical support structure makes us unique in offering power plant start-up and commissioning services on a variety of technologies in various challenging locations throughout the INDIA.
</p>
                                 </div>
                                  
                             </div>
                    </div>
                </section>

                <section class="overview-block-ptb bg-2">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="heading-title text-center">
                                    <h2 class="title hps-tw-7">Services We Provide</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-md-12  service-block">
							<div class="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25" >
								<div class="bg_overlay"></div>
								<div class="teaser_content">
									<div class="teaser text-center">
										<div class="teaser_icon grey size_big">
											<img src={Repairimg} />
										</div>
										<h4 class="text-uppercase topmargin_25">OPERATION & MAINTENANCE.</h4>
										<hr class="divider_2_40 bottommargin_25" />
										<p class="darken-font-color">
										 Building on its technical and organizational expertise, INDITRON offers complete range of Operations & Maintenance (O&M) services to power plant owners. The key objective is to keep customer's investments productive, by continuously optimizing their operations with an aim to enhance availability, output and efficiency of power plant along with power plant life. A scientific program of preventive maintenance and periodic repair ensures that the plant remains in peak condition.
										</p>
										<div class="governance-main-inner">
											<ul>
                                               <li>Detailed inspections, non-destructive testing and precision dimensioning</li>
										       <li>Condition monitoring and reliability services</li>
											   <li>On-site refurbishment and retrofitting</li>
											   <li>Installation and commissioning</li>
											  
											</ul>
										</div>
										
									</div>

								</div>
							</div>
						</div>
                        <div class="col-md-6 service-block half-block">
							<div class="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25" >
								<div class="bg_overlay"></div>
								<div class="teaser_content">
									<div class="teaser text-center">
										<div class="teaser_icon grey size_big">
											<img src={Porjectimg} />
										</div>
										<h4 class="text-uppercase topmargin_25">PROJECT CONSULTANT</h4>
										<hr class="divider_2_40 bottommargin_25" />
										<p class="darken-font-color">
										<strong>INDITRON ELECTRONICS</strong> have in house design capabilities with team of experts having expertise in project drawings and CAD programs. It Provide project consultancy. If you are coming up with a new Industrial unit or commercial construction, we can help you plan and design the electrical distribution systems for your unit. We can provide the electrical engineering consultancy on ring main unit design, engineering medium and low voltage switchgear, overhead line equipment. So from design to manufacturing, everything can be taken care under one roof. 
										</p>
										
									</div>

								</div>
							</div>
						</div>
                        <div class="col-md-6 service-block half-block">
							<div class="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25" >
								<div class="bg_overlay"></div>
								<div class="teaser_content">
									<div class="teaser text-center">
										<div class="teaser_icon grey size_big">
											<img src={Serviceimg} />
										</div>
										<h4 class="text-uppercase topmargin_25">SERVICES & AMC</h4>
										<hr class="divider_2_40 bottommargin_25" />
										<p class="darken-font-color">
										With years of experience and expertise in this domain, INDTRON are offering a satisfied Plant Electrical Items & water treatment plants maintenance service to our clients. We assure all our services are rendered under the audience of highly skilled and experienced electrical & process engineers who are well versed with the functionality of electrical items & water treatment plants. Further, these services are offered as per the requirement of the clients at market leading prices.
										</p>
										
									</div>

								</div>
							</div>
						</div>
                        
                        </div>
                    </div>
                    
               </section>
                 
            </div>
        );
    }
}
export default ServiceComponent;